import { axiosRequestSilent } from '../../apis/api-v1';

export const FTECHING_VIDEOS = "FTECHING_VIDEOS";
export const FTECHING_VIDEO_SUCCESS = "FTECHING_VIDEO_SUCCESS";
export const FTECHING_VIDEO_ERROR = "FTECHING_VIDEO_ERROR";


export const getTestSeriesList = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/testseries","/getTestListByCourseIdCategory",courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            let response = res.data.gatewayResponse.response || {}
            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: response })
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }

    } catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

export const getTestSeriesAntomyList = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/testseries","/getTestListBySubjectIdCategory",courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            let response = res.data.gatewayResponse.response || {}
            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: response })
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }

    } catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

export const getTestInstructions = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/testseries","/getTestDataById",courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            let response = res.data.gatewayResponse.response || {}
            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: response })
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }

    } catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

export const getAssesment = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/testseries","/postAssesment",courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            let response = res.data.gatewayResponse.response || {}
            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: response })
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }

    } catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}
export const getAssessmentResult = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/testseries","/submitTest",courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            let response = res.data.gatewayResponse.response || {}
            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: response })
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }

    } catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

export const getReviewAnswers = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/testseries","/reviewAnswers",courseBody,{},dispatch);
        if(!res.data.gatewayResponse.status.isSuccess){
            let base64ToString = Buffer.from(res.data.reviewJson, "base64").toString();
            base64ToString = JSON.parse(base64ToString);
            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: base64ToString })
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }

    } catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

export const postTestFeedBack = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/testseries","/postTestFeedBack",courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: res.data.gatewayResponse.status.message })
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }

    } catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

export const getTestDataByTestAssessmentId = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/testseries","/getTestDataByTestAssessmentId",courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: res.data.gatewayResponse.response.testData})
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }
    } catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}


