import indianColleges from './../assets/json/indianColleges.json';
import foreignCollege from './../assets/json/foreignCollege.json';

export default {
    getTime: val => {
        if (val) {
            let am = true
            let [h, m] = val.split(':')
            h = parseInt(h)
            if (h > 12) {
                h = (h - 12).toString()
                am = false
            }
            return h + ':' + m + ' ' + (am ? 'AM' : 'PM')
        } else return val
    },
    getUrl: (val = '') => {
        let value = val
        let label = val.replace('https://', '').replace('http://', '')
        if (!value.includes('http://') && !value.includes('https://')) value = 'https://' + value
        return { value, label }
    },
    getSocialIcon: host => {
        if (host.includes('instagram')) return 'fab fa-instagram'
        else if (host.includes('facebook')) return 'fab fa-facebook-f'
        else if (host.includes('twitter')) return 'fab fa-twitter'
        else if (host.includes('youtube') || host.includes('youtu.be')) return 'fab fa-youtube'
        else return 'fas fa-link'
    },
    getVideoData: url => {
        let host, newUrl = url
        if (url.includes('https://youtu.be/')) {
            host = 'youtube'
            newUrl = `https://www.youtube.com/embed/${url.split('https://youtu.be/')[1]}`
        } else if (url.includes('https://www.youtube.com/watch?v=')) {
            host = 'youtube'
            newUrl = `https://www.youtube.com/embed/${url.split('https://www.youtube.com/watch?v=')[1]}`
        }
        return { host, url: newUrl }
    },
    copyToClipboard: (text, cb) => {
        const el = document.createElement('textarea');
        el.value = text
        document.body.appendChild(el);
        el.select()
        el.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(el);
        cb && cb()
    },
    getNameUrl: name => {
        if (!name || typeof name !== 'string') return ''
        // return name.split(' ').filter(i => i).map(item => item.slice(0,1).toUpperCase().concat(item.slice(1))).join('')      
        return name.split(' ').filter(i => i).join('-').toLocaleLowerCase()
    },
    getDiscountPercent: (price, mrp) => {
        let discount = 0;
        if (price !== mrp) {
            discount = Math.round(((mrp - price) / (mrp)) * 100);
        }
        return discount;
    }
}

export const getCourseIdByName = (courseName)=>{
    console.log(courseName)
    switch (courseName) {
        case 'NEET-PG':
            return 1;
        case 'FMGE':
            return 3;
        case 'NEET-SS':
            return 2;
        case 'UG-CURRICULUM':
            return 1;
        case 'NEET-MDS':
            return 2;
        case 'BDS-NEXT':
            return 3;
        // case 'PG RESIDENCY':
        //     return 5;
        default:
            return 1;
    }
}
export const getCourseNameByIds = (courseid)=>{
    switch (courseid) {
        case 1:
            return 'NEET PG';
        case 2:
            return 'FMGE';
        case 3:
            return 'NEET SS';
        case 4:
            return 'UG CURRICULUM';
        // case 5:
        //     return 'PG RESIDENCY';
        default:
            return courseid;
            break;
    }
} 
export const getCourseNameById = (courseid)=>{
    switch (courseid) {
        case '1':
            return 'NEET PG';
        case '2':
            return 'FMGE';
        case '3':
            return 'NEET SS';
        case '4':
            return 'UG CURRICULUM';
        // case '5':
        //     return 'PG RESIDENCY';
        default:
            return 1;
            break;
    }
}


export const courseList = [{
    name: "UG CURRICULUM",
    id: 1
}, {
    name: "NEET MDS",
    id: 2
}, {
    name: "BDS NExT",
    id: 3
 },
//   {
//     name: "UG CURRICULUM",
//     id: 4
// },
//  {
//     name: "PG RESIDENCY",
//     id: 5
// }
]

export const yearCourseMap = (course, countryCode)=>{
    
    switch (course) {
        case "NEET PG":
            if (countryCode == 'IN')
            return [
                'MBBS Student-1st Year',
                'MBBS Student-2nd Year',
                'MBBS Student-3rd Year Part 1',
                'MBBS Student-3rd Year Part 2',
                'Intern/House Surgeon',
                'MBBS Graduate',
                'DNB',
                'PG Diploma'
            ]
        else
            return [
                'MBBS Student-1st Year',
                'MBBS Student-2nd Year',
                'MBBS Student-3rd Year',
                'MBBS Student-4th Year',
                'MBBS Student-5th Year',
                'Intern/House Surgeon',
                'MBBS Graduate',
                'DNB',
                'PG Diploma'
            ]
        case "UG CURRICULUM":
            if (countryCode == 'IN')
                return [
                    'I B.D.S',
                    'II B.D.S',
                    'III B.D.S',
                    'IV B.D.S',
                    'Intern',
                ]
            else
                return [
                    'I B.D.S',
                    'II B.D.S',
                    'III B.D.S',
                    'IV B.D.S',
                    'Intern',
                ]
        case "NEET SS":
            return [
                "PG-1st Year",
                "PG-2nd Year",
                "PG-3rd Year",
                "Post-Graduate",
                "DNB",
                "PG Diploma",
                "MBBS Graduate",
                "Intern/House Surgeon"
            ]
        case "PG RESIDENCY":
            return [
                "PG-1st Year",
                "PG-2nd Year",
                "PG-3rd Year",
                "Post-Graduate",
                "DNB",
                "PG Diploma",
                "MBBS Graduate",
                "Intern/House Surgeon",
                
            ]
        case "FMGE":
            return [
                "MBBS Student-1st Year",
                "MBBS Student-2nd Year",
                "MBBS Student-3rd Year",
                "MBBS Student-4th Year",
                "MBBS Student-5th Year",
                "Intern/House Surgeon",
                "MBBS Graduate",
                "DNB",
                "PG Diploma",
            ]
        case "NEET MDS":
            if (countryCode == 'IN')
                return [
                    'I B.D.S',
                    'II B.D.S',
                    'III B.D.S',
                    'IV B.D.S',
                    'Intern',
                ]
            else
                return [
                    'I B.D.S',
                    'II B.D.S',
                    'III B.D.S',
                    'IV B.D.S',
                    'Intern',
                ]
        case "BDS NExT":
            if (countryCode == 'IN')
                return [
                    'I B.D.S',
                    'II B.D.S',
                    'III B.D.S',
                    'IV B.D.S',
                    'Intern',
                ]
            else
                return [
                    'I B.D.S',
                    'II B.D.S',
                    'III B.D.S',
                    'IV B.D.S',
                    'Intern',
                ]
        
        default:
            return []
    }
}

export const stateList = [...new Set(indianColleges.map((item)=> item.state)),"Others"]

export const stateCollegeMap = (()=>{
    let stateCollegeMap = {
        "Others": [...foreignCollege.map((item)=>item.college)]
    }
    indianColleges.forEach((item)=>{
        if(!!stateCollegeMap[item.state]){
            stateCollegeMap[item.state].push(item.college);
        }else{
            stateCollegeMap[item.state] = [item.college];
        }
    })
    return stateCollegeMap
})()
export const foreignCollegeMap = (countryName) => foreignCollege.filter(i => i.state === countryName).map(i => i.college)
export const foreignCountries = (() => [...new Set(foreignCollege.map(i => i.state))])()

export const getSubArrayData = (arr,num=3)=>{
    let length = arr.length;
    let i = 0;
    let res = [];
    while(i < length){
        let currArray = arr.slice(i,i+num);
        res.push(currArray);
        i= i+num;
    }
    return res;
}