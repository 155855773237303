import React from 'react'

const Redirect = () => {
  return (
    <div>
      <h1>Redirect</h1>
    </div>
  )
}

export default Redirect
