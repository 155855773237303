import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { NavLink, useHistory } from 'react-router-dom/cjs/react-router-dom'
import { courses } from 'utils/courses';
import crossIcon from 'assets/Group 2910.png'


const LiveTestsPopUp = ({ homeData, isAuthenticated }) => {
    const history = useHistory()
    const [isOpen, setIsOpen] = useState(!Boolean(sessionStorage.getItem('homepageLoaded')));

    useEffect(()=>{
        sessionStorage.setItem('homepageLoaded','true') // Store that popup is already showm
    },[])
    
    const checkLiveTestForCourse = (courseId) => {
        return !!homeData && homeData.quizData && homeData.quizData.find(quizType => quizType.courseId === courseId)
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const changeCourse = (courseId) => {
        if (isAuthenticated) {
            localStorage.setItem('CourseKey', courseId);
            localStorage.removeItem('testseriestab');
            history.push('/dashboard/leaderboard');
            return;
        }
        history.push('/?login=true?leaderboard=true');
    }

    return (
        <Modal isOpen={isOpen} centered size="m" toggle={closeModal} >
        <ModalHeader>
            Live tests going on
            <img src={crossIcon} onClick={closeModal} role='button' alt='cross icon' className="cross-icon" height={20} />
        </ModalHeader>
        <ModalBody>
            <div className='class="py-3 px-3 p-md-5"'>
            {courses.map((course) => (
                <>
                    {checkLiveTestForCourse(course.id) &&
                    <>
                        <div onClick={() => changeCourse(course.id)} role='button' className='mb-3 text-primary text-center'>{course.liveTestText}</div>
                     </>   
                    }
                </>
            ))}
            </div>
        </ModalBody>
    </Modal>
    )
};

export default LiveTestsPopUp;

// render={props => (authenticationInProgress) ? <h3 className='text-center'><b>Loading...</b></h3>
//             : !isAuthenticated ? <Redirect to="/" /> : <Component {...props} />
//             }