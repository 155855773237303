import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {ToastContainer, toast} from 'react-toastify';

export const Notification = (props) => {
    const [tempNotifications, setTempNotifications] = useState([])
    const { notifications } = props.commonReducer;

    useEffect(() => {
        notifications.filter(item => !tempNotifications.includes(item)).forEach(notification => {
            createNotification(notification.type, notification.message)
        })
        setTempNotifications(notifications);
    }, [notifications]);

    const createNotification = (type, message, time) => {
        switch (type) {
            case 'info':
                toast.info(message);
                break;
            case 'success':
                toast.success(message);
                break;
            case 'warning':
                toast.warn(message);
                break;
            case 'error':
                toast.error(message);
                break;
        }
    }

    return (
            <ToastContainer />
    )
}
const mapStateToProps = (state)=>({
    commonReducer: state.commonReducer
})
export default connect(mapStateToProps,null)(Notification)