import { Button, Modal, ModalBody, Input as ReactInput, InputGroup, InputGroupText, Label } from "reactstrap"
import { Input } from "../components/Input"
import crossIcon from 'assets/Group 2910.png'
import { useHistory } from "react-router"
import { useEffect, useState, useMemo } from "react"
import { Form, Field } from 'react-final-form'
import { V, P } from 'utils'
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { withRouter } from "react-router"
import { addUser, verifyOTP, resendOTPFOrSignup } from './../store/authentication/action';
import country from './../assets/json/country.json';
import { courseList, yearCourseMap } from './../utils/methods';
import createDecorator from 'final-form-calculate';
import TCModal from "components/T&CModal"
import { getCollegeList, getCountryStateCodes } from "store/dashbaord/profile/action"


const Signup1 = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const [isOpen, setOpen] = useState(false)
    const [step, setStep] = useState(1)
    const [state, setState] = useState({ countryCode: '91' });
    const [stateForStep2, setStateForStep2] = useState({ success: false, data: null });
    const [termsOfServices, setTermsOfServices] = useState(false);
    const [showPass, setShowPass] = useState(false)
    const [stateList, setStateList] = useState([])
    const [foreignCountries, setForeignCountries] = useState([])
    const [collegeList, setCollegeList] = useState([])
    const [validate, setvalidate] = useState(false)

    const inputfocus = (elmnt) => {

        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            const next = elmnt.target.tabIndex;
            if (next < 6) {
                elmnt.target.form.elements[next].focus()
            }
        }

    }

    useEffect(() => {
        setOpen(history.location.search.includes('?signup=true'))
    }, [history.location.search])
    useEffect(() => {
        if (!isOpen) return
        setStep(1)
        setState({ countryCode: '91' })
        setStateList([])
        setStateForStep2({ success: false, data: null })
        setTermsOfServices(false)
        setShowPass(false)
    }, [isOpen])

    const closeModal = () => {
        setOpen(false)
        history.push({ search: '' })
    }
    const decorator = useMemo(() => createDecorator(
        {
            field: 'countryCode',
            updates: {
                stateName: (countryCode, values) => {
                    if (values.education !== 'FMGE' && ((countryCode === '91' && values.countryCode !== '91') || (countryCode !== '91' && values.countryCode === '91'))) return undefined
                    else return values.stateName
                },
                year: (countryCode, values) => {
                    if (yearCourseMap(values.education, countryCode).includes(values.year)) return values.year
                    else return undefined
                }
            }
        },
        {
            field: 'education',
            updates: {
                year: (education) => {
                    if (education) {
                        const courseId = courseList.find(({ name }) => name == education).id
                        getCountryStateCodes(courseId, 91).then(setStateList)
                        getCountryStateCodes(courseId, 92).then(setForeignCountries)
                    }
                    return undefined
                },
                stateName: () => undefined
            }
        },
        {
            field: 'stateName',
            updates: {
                college: (stateName, values) => {
                    const courseId = courseList.find(({ name }) => name == values?.education)?.id
                    if (stateName) getCollegeList(courseId, stateName, values.specialization).then(setCollegeList)
                    return undefined
                }, 
                year: () => undefined
            }
        }
    ), [])
    const validators = (values) => ({
        name: V.compose(V.required, V.name)(values.name) || undefined,
        email: V.compose(V.email, V.required)(values.email) || undefined,
        education: V.required(values.education) || undefined,
        year: V.required(values.year) || undefined,
        stateName: V.required(values.stateName) || undefined,
        college: V.required(values.college) || undefined,
        password: V.compose(V.required, V.password)(values.password) || undefined,
        countryCode: V.required(values.countryCode) || undefined,
        mobileNo: V.compose(V.required, values.countryCode == "91" ? V.mobile : V.mobile_state)(values.mobileNo) || undefined,
        termsCond: V.required(values.termsCond) || undefined,
        specialization: values.education === 'NEET SS' ? V.required(values.specialization) || undefined : undefined
    })
    const handleResendOTP = async () => {
        const data = await resendOTPFOrSignup({ countryCode: state.countryCode, mobileNo: state.mobileNo }) || {};
        setStateForStep2({ ...stateForStep2, data: { ...stateForStep2.data, ...data } })
    }
    const handleSubmit = async (values, form) => {
        const redirectPath = history.location.search.includes('sameuri=true') ? history.location.pathname : '/dashboard'
        if (step === 1) {
            setState({ ...state, ...values })
            const college = collegeList.find(({ collegeId }) => collegeId == values.college)
            const body = {
                name: values.name,
                countryCode: parseInt(values.countryCode),
                mobileNo: P.number(values.mobileNo),
                email: values.email,
                courseId: courseList.find(i => i.name === values.education)?.id,
                state: (values.countryCode === "91" && values.education !== "FMGE" ? stateList : foreignCountries).find(({ id }) => id == values.stateName)?.state,
                stateId: parseInt(values.stateName),
                college: college?.collegeName,
                collegeId: college?.collegeId,
                universityId: college?.universityId,
                education: values.year,
                password: values.password,
                specialization: values.specialization,
                referralCode:values.referralCode,
                "ios": false,
                "android": false,
            }
            const { success, data } = await dispatch(addUser(body));
            if (success) {
                setStep(2);
                setStateForStep2({
                    ...stateForStep2,
                    success,
                    data
                })
            }
        } else {
            form.reset()
            const OTP = new Array(4).fill(0).map((_, i) => values[`otp_${i}`]).join('');
            const { success } = await dispatch(verifyOTP({ ...stateForStep2.data, otp: OTP }));
            if (success) {
                setOpen(false)
                history.push(redirectPath);
            }
        }
    }

    return (
        <Modal isOpen={isOpen} centered size="lg" toggle={closeModal}>
            <ModalBody>
                <div className="py-5 p-md-5" id="signUp">
                    <img src={crossIcon} alt='close icon' className="cross-icon" height={20} role='button' onClick={closeModal} />
                    <div className="text-center">
                        <h2><b>Create an Account</b></h2>
                    </div>
                    {step === 1 ? (<Form decorators={[decorator]}  validate={validate ? validators:()=>{}} onSubmit={handleSubmit} initialValues={state} render={({ handleSubmit, values, form, hasValidationErrors, ...args }) => (<>
                        <form onSubmit={(values) => handleSubmit(values, form)} className='mt-5 row'>
                            <div className="col-12">
                                <Field name='name'>
                                    {({ input, meta }) => {
                                        return <Input {...input} {...meta} type="text" className="mb-4" placeholder="Full Name" maxLength={45} />
                                    }}
                                </Field>
                            </div>
                            <Field name='email'>
                                {({ input, meta }) => (
                                    <>
                                        <Input {...input} {...meta} type="text" className="col-12 col-md-6 mb-4" placeholder="Email Id" />
                                    </>
                                )}
                            </Field>
                            <div className="col-12 col-md-6 mb-4">
                                <div className="row">
                                    <Field name='countryCode'>
                                        {({ input, meta }) => (
                                            <>
                                                <Input {...input} {...meta} type='select' className="d-inline-flex col-4 col-md-3 ms-auto pr-0">
                                                    <option value={""}>Select Country Code</option>
                                                    <option value={"91"}>+91</option>
                                                    {Object.keys(country).sort((a, b) => a - b).map((item, i) => (
                                                        <option value={country[item][1]} key={i}>+{country[item][1]}</option>))
                                                    }
                                                </Input>
                                            </>
                                        )}
                                    </Field>
                                    <Field name='mobileNo'>
                                        {({ input, meta }) => (
                                            <>
                                                <Input {...input}  {...meta} type="text" onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} maxLength={10} className="col me-auto" placeholder="9999999999" />
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </div>

                            <Field name='password'>
                                {({ input, meta }) => (
                                    <div className="col-12 col-md-6 mb-4">
                                        <InputGroup>
                                            <Input {...input} {...meta} hideError className="form-control p-0 shadow-none" type={showPass ? "text" : "password"} placeholder="Password" />
                                            <InputGroupText className="text-primary bg-transparent" role='button' onClick={() => setShowPass(!showPass)}>{showPass ? 'HIDE' : 'SHOW'}</InputGroupText>
                                        </InputGroup>
                                        {meta.touched && !meta.valid && meta.error && <div className="text-danger">{meta.error}</div>}
                                    </div>
                                )}
                            </Field>
                            <Field name='education'>
                                {({ input, meta }) => (
                                    <>
                                        <Input {...input} {...meta} type="select" className="col-12 col-md-6 mb-4" placeholder="Enter Your Course" >
                                            <option value={''} disabled>Enter Your Course</option>
                                            {courseList.slice(0,1).map((course, i) => <option value={course.name} key={i}>{course.name}</option>)}
                                        </Input>
                                    </>
                                )}
                            </Field>
                            {values.education === 'NEET SS' && (
                                <Field name='specialization'>
                                    {({ input, meta }) => (
                                        <>
                                            <Input {...input} {...meta} type="select" className="col-12 col-md-6 mb-4" placeholder="Enter Your Specialization" >
                                                <option value={''} disabled>Enter Your Specialization</option>
                                                {['MCh', 'DM'].map((item, i) => <option value={item} key={i}>{item}</option>)}
                                            </Input>
                                        </>
                                    )}
                                </Field>
                            )}

                            {values.countryCode === "91" && values.education !== "FMGE" ? (<>
                                <Field name='stateName'>
                                    {({ input, meta }) => (
                                        <>
                                            <Input {...input}  {...meta} type="select" className="col-12 col-md-6 mb-4" placeholder="Enter Your State">
                                                <option value={""} disabled>Enter Your State/Country</option>
                                                {stateList.map((item, i) => <option value={item.id} key={i}>{item.state}</option>)}
                                            </Input>
                                        </>
                                    )}
                                </Field>
                            </>) : (<>
                                <Field name='stateName'>
                                    {({ input, meta }) => (
                                        <>
                                            <Input {...input}  {...meta} type="select" className="col-12 col-md-6 mb-4" placeholder="Enter Your Country">
                                                <option value={""} disabled>Enter Your Country</option>
                                                {foreignCountries.map((item, i) => (
                                                    <option value={item.id} key={i}>{item.state}</option>))
                                                }
                                            </Input>
                                        </>
                                    )}
                                </Field>
                            </>)}
                            <Field name='college'>
                                {({ input, meta }) => (
                                    <>
                                        <Input {...input} {...meta} type="select" className="col-12 col-md-6 mb-4" placeholder="Enter Your College" >
                                            <option value={''} disabled>Enter Your College</option>
                                            {collegeList.map((college, i) => <option value={college.collegeId} key={i}>{college.collegeName}</option>)}
                                        </Input>
                                    </>
                                )
                                }
                            </Field>
                            <Field name='year'>
                                {({ input, meta }) => (
                                    <>
                                        <Input {...input} {...meta} type="select" className="col-12 col-md-6 mb-4" placeholder="Enter Your Year" >
                                            <option value={''} disabled>Enter Your Education</option>
                                            {yearCourseMap(values.education, collegeList?.[0]?.countryCode).map((item, i) => <option value={item} key={i}>{item}</option>)}
                                        </Input>
                                    </>
                                )}
                            </Field>
                            <Field name='referralCode'>
                                {({ input, meta }) => {
                                    return <Input {...input} {...meta} type="text" className="col-12 col-md-6 mb-4" placeholder="referral Code" maxLength={45} />
                                }}
                            </Field>
                            <Field name='termsCond' type="checkbox">
                                {({ input, meta }) => (
                                    <>
                                        <div className="col-12">
                                            <div className="ms-4">
                                                <Label check>
                                                    <ReactInput {...input} id="accept-checkbox" /> <span className="ms-2">I accept Cuspyd - </span>
                                                </Label>
                                                <span className="text-primary text-underline" onClick={() => setTermsOfServices(true)}>Terms & Conditions</span>
                                            </div>
                                        </div>
                                        <p className="text-danger sm-font mt-1" style={{ height: '10px' }}>{meta.touched && meta.error ? 'You must agree terms and condition.' : ''}</p>
                                    </>
                                )}
                            </Field>
                            <div className="mt-4 text-center">
                                {console.log({ hasValidationErrors, values })}
                                { values.termsCond ? 
                                <Button color="light" className={hasValidationErrors ? '' : 'highlight'} type="submit">Signup</Button>
                                :<Button color="light" className={hasValidationErrors ? '' : 'highlight'} type="submit" disabled >Signup</Button>}
                            </div>
                            <h5 className="mt-4 text-center">
                                <b>Already have an account? <Link to='?login=true' className="text-orange">Login</Link></b>
                            </h5>
                        </form>
                    </>)} />) : (<Form onSubmit={handleSubmit} initialValues={state} render={({ handleSubmit }) => (<>
                        <form onSubmit={handleSubmit} className='mt-5 row justify-content-center'>
                            <div className="col-12 text-center mb-5">
                                Please enter your OTP, sent to {state.countryCode} {state.mobileNo} <b className="text-underline ms-2" role='button' onClick={() => setStep(1)}>Change phone number</b>
                            </div>
                            {new Array(4).fill(0).map((_, i) => (
                                <Field name={`otp_${i}`} parse={P.number}>
                                    {({ input, meta }) => (
                                        <Input {...input} tabIndex={i + 1} onKeyUp={e => inputfocus(e)} {...meta} type='text' className='col-auto' inputClassName={`otp-input text-center ${input.value ? 'border-orange' : 'border-black'}`} maxLength={1} />
                                    )}
                                </Field>
                            ))}
                            <div className="mt-5 text-center">
                                <Button color="light" type="submit">SUBMIT</Button>
                            </div>
                            <h5 className="mt-5 text-center">
                                <span className="text-primary text-underline" onClick={handleResendOTP}>Re-send OTP</span>
                            </h5>
                        </form>
                    </>)} />)}
                    {termsOfServices && <TCModal
                        isOpen={termsOfServices}
                        setTermsOfServices={setTermsOfServices}
                    />}
                </div>
            </ModalBody>
        </Modal>
    )
}
export const Signup = withRouter(Signup1);