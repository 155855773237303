import { axiosRequestSilent } from '../../apis/api-v1';

export const FTECHING_VIDEOS = "FTECHING_VIDEOS";
export const FTECHING_VIDEO_SUCCESS = "FTECHING_VIDEO_SUCCESS";
export const FTECHING_VIDEO_ERROR = "FTECHING_VIDEO_ERROR";
export const AttemptHistoryList = "AttemptHistoryList";
export const AttemptHistoryList_ERROR = "AttemptHistoryList_ERROR";
export const AttemptHistoryList_VIDEOS  = "AttemptHistoryList_VIDEOS";


export const getQB_Anatomy = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/qb",courseBody.bookmarks ? "/getBookmarksInSubject" : "/getQBTopicModule", courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: res.data.gatewayResponse.response.subject[0]  })
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }

    } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

export const getModuleAttemptHistoryList1 = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/qb","/getModuleAttemptHistoryList",courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            dispatch({ type: AttemptHistoryList, res: res.data.gatewayResponse.response.subject[0]  })
        }else{
            dispatch({ type: AttemptHistoryList_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }

    } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
            dispatch({ type: AttemptHistoryList_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: AttemptHistoryList_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

 
export const getModuleAttemptHistoryList = async (body)=> {
    try {
        const res = await axiosRequestSilent("POST","/qb","/getModuleAttemptHistoryList",body,{});
        if(res.data.gatewayResponse.status.isSuccess){
            return res.data.gatewayResponse;
        }else{
            return res.data.gatewayResponse;
        }
    } catch (error) {
        throw new Error("Could not getModuleAttemptHistoryList end Otp");
    }
}

