import { useState } from "react"
import { Button, ButtonGroup } from "reactstrap"
import infoIcon from 'assets/Information.png'
import { useEffect } from "react"
import { Carousel } from 'react-responsive-carousel';
import PlanModal from "./PlanModal";
import { Link, useHistory } from 'react-router-dom'
import { useRef } from 'react';


const getSubArrayData = (arr,num=3)=>{
    let length = arr.length;
    let i = 0;
    let res = [];
    while(i < length){
        let currArray = arr.slice(i,i+3);
        res.push(currArray);
        i= i+num;
    }
    return res;
}

const nameToUrlMapping = {
    "UG Curriculum": "ug-curriculum",
    "NEETPG": "neet-pg",
    "FMGE": "fmge"
}

export const Plans = (props) => {
    const CarouselRef = useRef(null)
    const [selected, setSelected] = useState(props.plans.map((item)=>item.name)[0])
    const [listButton, setListButton] = useState([...props.plans.map((item) => item.name)]);
    const [currPlans,setCurrentPlans] = useState([[]]);
    const [showInfo,setShowInfo] = useState(null);
    const isMobile = window.innerWidth <= 767;
    
    useEffect(()=>{
        props.plans.map((item)=>{
            if(item.name == selected){
                //setCurrentPlans(getSubArrayData([...item.plans],3));
                setCurrentPlans(getSubArrayData([...item.plans],3));
            }
        })
    },[selected]);

    useEffect(() => {
        if (props.courseId) {
            updateCoursePlans();
        }
    }, [props.courseId]);

    const updateCoursePlans = () => {
        setSelected(props.plans.map((item) => item.name)[0]);
        setListButton([...props.plans.map((item) => item.name)])
    }

    const checkPlansLength = (currPlans) =>{
        return (!!currPlans && currPlans.length > 0 && currPlans[0].length>0)
    }


    return (
        <>
            <div className='mt-5 plans'>
                <h1 className='text-center font-medium'>Our Flexible Plans & Pricing</h1>
                <div className='text-center mt-3'>
                    <ButtonGroup className='bg-light-gray shadow'>
                        {listButton.map((item, i) => <Button key={i} onClick={() => {setSelected(item);CarouselRef.current.moveTo(0)}} color={selected === item ? 'primary' : 'link'}>{item}</Button>)}
                    </ButtonGroup>
                </div>
                {checkPlansLength(currPlans) && 
                isMobile ? currPlans.map((planP)=>(
                    <div className="row mt-md-5" key={Math.random()}>
                        {!!planP && planP.map((plan)=>(
                            <div className='col-12 col-md-4' key={Math.random()}>
                                <div className='card shadow rounded-0 text-center m-4' style={{height: '90%'}}>
                                    <div className='bg-light-blue p-3'>
                                        <h4><b>{plan.name}</b> 
                                        {/* {plan.name == "DOC-D"? null:<img src={infoIcon} onClick={()=>setShowInfo(plan)} alt='info icon' className='ms-3 mw-30 cursor-pointer' />} */}
                                        </h4>
                                        <div>{plan.heading}</div>
                                    </div>
                                    <div className='p-5'>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <h2>₹ <b>{plan.discountedPrice}</b></h2> <h1 className='ms-3'><b>/</b></h1> <h4 className='text-gray ms-3 text-line'>₹ {plan.originalPrice}</h4>
                                            {/* {plan.name == "DOC-D"?null:<><h1 className='ms-3'><b>/</b></h1> <h4 className='text-gray ms-3 text-line'>₹ {plan.originalPrice}</h4></>} */}
                                        </div>
                                        {/* <Button color='blue' className='mt-5 px-5'>Subscribe</Button> */}
                                            <Link to={`/plans/${nameToUrlMapping[selected]}`}  color='blue' className='mt-5 px-5 btn btn-blue'>Subscribe</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    ))

                : <Carousel autoPlay interval={5000} ref={CarouselRef} showThumbs={false} infiniteLoop>
                    {currPlans.map((planP)=>(
                    <div className="row mt-md-5" key={Math.random()}>
                        {!!planP && planP.map((plan)=>(
                            <div className='col-12 col-md-4' key={Math.random()}>
                                <div className='card shadow rounded-0 text-center m-4 justify-content-between' style={{height: '90%'}}>
                                    <div className='bg-light-blue p-3'>
                                        <h4><b>{plan.name}</b> 
                                        {/* {plan.name == "DOC-D"? null:<img src={infoIcon} onClick={()=>setShowInfo(plan)} alt='info icon' className='ms-3 mw-30 cursor-pointer' />} */}
                                        </h4>
                                        <div>{plan.heading}</div>
                                    </div>
                                    <div className='p-5'>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <h2>₹ <b>{plan.discountedPrice}</b></h2> <h1 className='ms-3'><b>/</b></h1> <h4 className='text-gray ms-3 text-line'>₹ {plan.originalPrice}</h4>
                                            {/* {plan.name == "DOC-D"?null:<><h1 className='ms-3'><b>/</b></h1> <h4 className='text-gray ms-3 text-line'>₹ {plan.originalPrice}</h4></>} */}
                                        </div>
                                        {/* <Button color='blue' className='mt-5 px-5'>Subscribe</Button> */}
                                            <Link to={`/plans/${nameToUrlMapping[selected]}`}  color='blue' className='mt-5 px-5 btn btn-blue'>Subscribe</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    ))}
                </Carousel>}
                
            </div>
            {showInfo && <PlanModal isOpen={!!showInfo} plan={showInfo} toggleModal={()=>setShowInfo(false)}/>}
        </>


    )
}