import React from 'react';
import ReactPlayer from 'react-player'
import { Modal, ModalBody } from "reactstrap"

const VideoModal = (props) => {
    const {isOpen,closeModal} = props;
    return (
        <div>
            <Modal isOpen={isOpen} centered size="lg" toggle={closeModal}>
                <ModalBody className='p-1 rounded'>
                    <ReactPlayer
                        url={"https://d2p0vg0bzbv2mt.cloudfront.net/WebV2/Promo%20V2.023.mp4"}
                        controls
                        playing={true}
                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                        onContextMenu={e => e.preventDefault()}
                        width={"100%"}
                        height={"100%"}
                    />
                </ModalBody>
            </Modal>
        </div>
    )
}

export default VideoModal