import { FTECHING_VIDEOS, FTECHING_VIDEO_SUCCESS, FTECHING_VIDEO_ERROR } from './action';

export const initialState = {
    fetchingQB: false,
    QBInfo: null,
    errMsg: null
}

const reducer = (state = initialState, action)=>  {
    switch (action.type) {
        case FTECHING_VIDEOS: {
            return {
                ...state,
                fetchingQB: true,
            }
        }
        case FTECHING_VIDEO_SUCCESS: {
            return {
                ...state,
                fetchingQB: false,
                QBInfo: action.res,
                errMsg: null
            }
        }
        case FTECHING_VIDEO_ERROR: {
            return {
                ...state,
                fetchingQB: false,
                QBInfo: null,
                errMsg: action.errMsg
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}
export default reducer;