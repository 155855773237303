import { FTECHING_VIDEOS, FTECHING_VIDEO_SUCCESS, FTECHING_VIDEO_ERROR ,  AttemptHistoryList_ERROR , AttemptHistoryList } from './action';
import { UPDATE_QUESTION_BOOKMARK } from '../anatomyExam/action';

export const initialState = {
    fetchingQB: false,
    QBInfo: null,
    AttemptHistoryList:null,
    errMsg: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FTECHING_VIDEOS: {
            return {
                ...state,
                fetchingQB: true,
            }
        }
        case FTECHING_VIDEO_SUCCESS: {
            return {
                ...state,
                fetchingQB: false,
                QBInfo: action.res,
                errMsg: null
            }
        }
        case FTECHING_VIDEO_ERROR: {
            return {
                ...state,
                fetchingQB: false,
                QBInfo: null,
                errMsg: action.errMsg
            }
        }
        case AttemptHistoryList: {
            return {
                ...state,
                fetchingQB: false,
                AttemptHistoryList: null,
                errMsg: action.errMsg
            }
        }
        case AttemptHistoryList_ERROR: {
            return {
                ...state,
                fetchingQB: false,
                AttemptHistoryList: null,
                errMsg: action.errMsg
            }
        }
        case UPDATE_QUESTION_BOOKMARK: {
            let questionIndex = state.QBInfo.findIndex(i => i.questionId == action.payload.questionId)
            if (questionIndex === -1) return state
            else return { ...state, QBInfo: [...state.QBInfo.slice(0, questionIndex), { ...state.QBInfo[questionIndex], bookmarked: action.payload.bookmarked }, ...state.QBInfo.slice(questionIndex + 1)]}
        }
        default: {
            return {
                ...state
            }
        }
    }
}
export default reducer;