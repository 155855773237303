import React from 'react'
import { Spinner } from 'reactstrap'

export const Loader = (props) => {
    return (
        <div className='loader-component'>
            <div>
                <div className='text-center'>
                    <Spinner color='primary' />
                </div>
            </div>
        </div>
    )
}