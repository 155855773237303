import React, { useEffect } from 'react';
import { Route, useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { tryToAuthenticate } from 'store/authentication/action';

const PublicRoute = ({ component: Component, authState, tryTologin, ...rest }) => {
    const {authenticationInProgress,isAuthenticated} = authState;
    const history = useHistory();
    
    useEffect(async ()=>{
        const prevPath = localStorage.getItem("prevPath");
        if(isAuthenticated){
            if(!!prevPath && prevPath != "/"){
                history.push(prevPath);
            }
            // else{
            //     history.push("/dashboard");
            // }
        }
        // if(!isAuthenticated && !authenticationInProgress){
        //     const bool = await tryTologin();
        //     if(bool && !!prevPath && prevPath != "/"){
        //         history.push(prevPath);
        //     }else if(bool){
        //         history.push("/dashboard");
        //     }
        // }
    },[isAuthenticated]);

    return (
        <Route
            {...rest}
            render={props=> <Component {...props} /> 
            }
        />
    )
};
const mapStateToProps = (state) => ({
    authState: state.authReducer,
});
const mapDispatchToProps = (dispatch)=>({
    tryTologin: ()=> dispatch(tryToAuthenticate())
})
export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute);

// render={props => (authenticationInProgress) ? <h3 className='text-center'><b>Loading...</b></h3>
//             : !isAuthenticated ? <Redirect to="/" /> : <Component {...props} />
//             }