import React,{useState, useEffect} from 'react';
import { Modal, ModalBody } from "reactstrap";
import { courseList } from 'utils/methods';
import crossIcon from './../assets/Group 2910.png';

const ChangeCourseModal = (props) => {
    const {isOpen,closeModal} = props;
    const [courseId,setCourse] = useState();

    const handleChangeCourse = ()=>{
        localStorage.setItem('CourseKey', courseId);
        localStorage.removeItem('testseriestab')
        closeModal();
        props.refreshPage();
    }
    useEffect(() => {
       const courseId = localStorage.getItem('CourseKey');
       setCourse(courseId || props.courseid)
    }, []);

  return (
    <div>
        <Modal isOpen={isOpen} centered size="lg" toggle={closeModal}>
            <ModalBody className='p-0 pt-2'>
                <div className='p-0'>
                    <img src={crossIcon} alt='closeIcon' className="cross-icon pb-2" height={20} role='button' onClick={closeModal} />
                    <div className="text-center py-1 m-0">
                        <h2 className='m-0'>Choose your dream</h2>
                    </div>
                </div>
                <hr className='mt-0'/>
                <div className='d-flex justify-content-center  mb-0 p-2 flex-column flex-md-row align-items-center gap-2'>
                    {courseList.slice(0,1).map((item)=>(
                        <div>
                            {courseId == item.id?<button onClick={()=>setCourse(item.id)} className='btn btn-primary m-md-3 m-sm-2 mx-1'>{item.name}</button>
                            :
                            <button onClick={()=>setCourse(item.id)} className='btn btn-outline-secondary m-md-3 m-sm-2 mx-xs-1'>{item.name}</button>
                            }
                       </div>
                    ))}
                   
                </div>
                <div className='d-flex justify-content-center mt-0'>
                    {courseList.slice(3).map((item)=>(
                        <div>
                            {courseId == item.id?<button onClick={()=>setCourse(item.id)} className='btn btn-primary m-md-3 m-sm-2 m-xs-1'>{item.name}</button>
                            :
                            <button onClick={()=>setCourse(item.id)} className='btn btn-outline-secondary m-md-3 m-sm-2 m-xs-1'>{item.name}</button>
                            }
                       </div>
                    ))}
                </div>
                <div className='d-flex justify-content-center my-4'>
                    <div>
                        <button onClick={handleChangeCourse} className='btn btn-blue'>PROCEED</button>
                    </div>
                </div>

            </ModalBody>
        </Modal>
    </div>
  )
}

export default ChangeCourseModal