import Axios from "axios";
import * as commonActions from 'store/actions/common'
import cookies from 'react-cookies'
import { toast } from 'react-toastify';
import  store  from '../../store'
import { BASE_URL } from "./api";

export async function axiosRequestNA(method, url,serreqId="", body = null,config={},dispatch, notify = true) {
    let URL = `${BASE_URL}${url}`;
    dispatch(commonActions.loader(true))
    return Axios({
        method: method,
        url: URL,
        headers: {
			"apiversion": "1.0.0.0",
			"appversion": "1.0.0.0",//
			"channelid": "Web",
			'Content-Type':'application/json',
			'SerreqId': serreqId,
            "SessionToken": cookies.load('sessionToken'),
			...config.headers
		},
        data: JSON.stringify({
			gatewayRequest: {
				request: {
					...body,
				}
			}
		})
    })
        .then((res) => {
          //  console.log(res);
            if(!res.data?.gatewayResponse?.status?.isSuccess && notify){
                dispatch(commonActions.notify("error",res.data?.gatewayResponse?.status?.message))
            }
            return res;
        })
        .catch((err) => {
            throw err;
        }).finally((_)=>{
            dispatch(commonActions.loader(false))
            
        });
}
export const resetWebsessionToken = ()=>{
    console.log("calling the reset websession token ...function");
    const SessionToken = cookies.load("sessionToken");
    const RefreshToken = cookies.load("refreshToken");
    const studentId = cookies.load("studentId");
    let URL = `${BASE_URL}/student`;
    if(!!SessionToken && !!RefreshToken && !!studentId){
        return Axios({
            method: "POST",
            url: URL,
            headers: {
                "apiversion": "1.0.0.0",
                "appversion": "1.0.0.0",
                "channelid": "Web",
                'Content-Type':'application/json',
                'SerreqId': "/restWebSessionToken",
                "SessionToken": SessionToken,
                "RefreshToken": RefreshToken,
            },
            data: JSON.stringify({
                gatewayRequest: {
                    request: {
                        studentId,
                    }
                }
            })
        }).then((res) => {
                if(res.data.gatewayResponse.status.isSuccess){
                    const response = res.data.gatewayResponse.response;
                    const refreshToken = response?.refreshToken;
                    const sessionToken = response?.sessionToken;
                    const studentId = response?.studentId;
                    console.log(refreshToken,sessionToken,studentId);

                    if(!!refreshToken && !!sessionToken && !!studentId){
                        console.log("saving to cookies...");
                        cookies.remove("sessionToken");
                        cookies.save("sessionToken",sessionToken);
                        cookies.remove("refreshToken");
                        cookies.save("refreshToken",refreshToken);
                        cookies.remove("studentId");
                        cookies.save("studentId",studentId);
                        return true;
                    }else{
                        return false;
                    } 
                }else{
                    return false;
                }
            })
            .catch((err) => {
               console.log(err);
               return false;
            });
    }else{
        return false;
    }

}

// function testFn(){
//     return new Promise(resolve => setTimeout(() => resolve("result"),3000));
//   }
  
//   testFn().then(res => console.log(res)); 

export async function axiosRequestSilent(method, url,serreqId="", body = null,config={}) {
    const { dispatch } = store
    if (method !== 'GET') dispatch(commonActions.loader(true))
    let URL = `${BASE_URL}${url}`;
    const headers = {
        "apiversion": "1.0.0.0",
        "appversion": "1.0.0.0",
        "channelid": "Web",
        'Content-Type':'application/json',
        'SerreqId': serreqId,
        "SessionToken": cookies.load('sessionToken'),
        ...config.headers
    }
    const data = JSON.stringify({ gatewayRequest: {request: {...body}}});
    try {
        // testFn();
        const res = await Axios({method: method,url:URL,data: data,headers: headers});
        return res;
    } catch (error) {
        if(error.response.status == 403){
            const test = await resetWebsessionToken();
            if(test){
                const res = await Axios({method: method,url:URL,data: data,headers: headers});
                return res;
            }else{
                throw error;
            }
        }else if(error.response.status == 500){
            cookies.remove("sessionToken");
            cookies.remove("refreshToken");
            cookies.remove("studentId");
            localStorage.removeItem("prevPath");
            localStorage.removeItem('localStoragedata');
            localStorage.clear();
            // window.location.reload(true);
            window.location.href = '/';
            console.log(error);
            throw error;
        }
        else{
            console.log(error);
            throw error;
        }
    } finally {
        if (method !== 'GET') dispatch(commonActions.loader(false))
    }
}

export async function axiosRequestSilentPublic(method, url,serreqId="", body = null,config={}) {
    let URL = `${BASE_URL}${url}`;
    const headers = {
        "apiversion": "1.0.0.0",
        "appversion": "1.0.0.0",
        "channelid": "Web",
        'Content-Type':'application/json',
        'SerreqId': serreqId,
        ...config.headers
    }
    const data = JSON.stringify({ gatewayRequest: {request: {...body}}});
    try {
        // testFn();
        const res = await Axios({method: method,url:URL,data: data,headers: headers});
        return res;
    } catch (error) {
        if(error.response.status == 403){
            const test = await resetWebsessionToken();
            if(test){
                const res = await Axios({method: method,url:URL,data: data,headers: headers});
                return res;
            }else{
                throw error;
            }
        }else if(error.response.status == 404   ){
            cookies.remove("sessionToken");
            cookies.remove("refreshToken");
            cookies.remove("studentId");
            localStorage.removeItem("prevPath");
            localStorage.removeItem('localStoragedata');
            localStorage.clear();
            toast.error('404 The document you requested can not be found on this server.')
            throw error;
        }else if(error.response.status == 500   ){
            cookies.remove("sessionToken");
            cookies.remove("refreshToken");
            cookies.remove("studentId");
            localStorage.removeItem("prevPath");
            localStorage.removeItem('localStoragedata');
            localStorage.clear();
            // window.location.reload(true);
            window.location.href = '/';
            console.log(error);
            throw error;
        }
        else{
            console.log(error);
            throw error;
        }
    }
}


// export async function axiosRequestSilentPublic1(method, url,serreqId="", body = null,config={}) {
//     let URL = `${BASE_URL}${url}`;
//     return Axios({
//         method: method,
//         url: URL,
//         headers: {
// 			"apiversion": "1.0.0.0",
// 			"appversion": "1.0.0.0",
// 			"channelid": "Web",
// 			'Content-Type':'application/json',
// 			'SerreqId': serreqId,
// 			...config.headers
// 		},
//         data: JSON.stringify({
// 			gatewayRequest: {
// 				request: {
// 					...body,
// 				}
// 			}
// 		})
//     })
//         .then((res) => res)
//         .catch((err) => {
//             throw err;
//         });
// }

