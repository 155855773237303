import React,{useEffect} from 'react'
import { axiosRequestSilent } from 'store/apis/api-v1';
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";


const VerifyEmail = () => {
    const { tokenId } = useParams();
    const history = useHistory();
    const verificationEmail = async () => {
        
        const mailVerificationToken = tokenId
        try {
            const res = await axiosRequestSilent("POST", "/student", "/verifyEmail", { mailVerificationToken }, {});
            if (res.data.gatewayResponse.status.isSuccess) {
                toast.success(res.data.gatewayResponse.status.message, { hideProgressBar: true });
                history.push('/dashboard');
            } else {
                toast.error("something went wrong.", { hideProgressBar: true });
            }
        } catch (error) {
            toast.error("something went wrong.", { hideProgressBar: true });
        }
    }

    useEffect(() => {
        verificationEmail()
    }, [])

  return (
    <div>
      <></>
    </div>
  )
}

export default VerifyEmail
