import { axiosRequestSilent } from '../../apis/api-v1';

export const FTECHING_VIDEOS = "FTECHING_VIDEOS";
export const FTECHING_VIDEO_SUCCESS = "FTECHING_VIDEO_SUCCESS";
export const FTECHING_VIDEO_ERROR = "FTECHING_VIDEO_ERROR";


export const ongoing = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/leaderBoard","/ongoing",courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            let response = res.data.gatewayResponse.response || {}
            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: response })
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }

    } catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

export const upcoming = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/leaderBoard","/upcoming",courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            let response = res.data.gatewayResponse.response || {}
            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: response })
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }

    } catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

export const completed = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/leaderBoard","/completed",courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            let response = res.data.gatewayResponse.response || {}
            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: response })
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }

    } catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

export const getQuizData = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/leaderBoard","/getQuizData",courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            let response = res.data.gatewayResponse.response.getQuizData || {}
            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: response })
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }

    } catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

export const postAssessment = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/leaderBoard","/postAssessment",courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            let response = res.data.gatewayResponse.response || {}
            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: response })
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }

    } catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

export const submitTest = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/leaderBoard","/lbSubmitTest",courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            let response = res.data.gatewayResponse.response || {}
            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: response })
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }

    } catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

export const getQuizResult = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/leaderBoard","/getQuizResult",courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            let response = res.data.gatewayResponse.response || {}
            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: response })
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }

    } catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

export const reviewQuiz = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/leaderBoard","/reviewQuiz",courseBody,{},dispatch);
        if(!res.data.gatewayResponse.status.isSuccess){
            let base64ToString = Buffer.from(res?.data?.gatewayResponse?.reviewJson, "base64").toString();
            base64ToString = JSON.parse(base64ToString);
            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: base64ToString })
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }

    } catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

export const quizFeedback = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/leaderBoard","/quizFeedback",courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: res.data.gatewayResponse.status.message })
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }

    } catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

