import { axiosRequestSilent } from './../../apis/api-v1';

export const FTECHING_VIDEOS = "FTECHING_VIDEOS";
export const FTECHING_VIDEO_SUCCESS = "FTECHING_VIDEO_SUCCESS";
export const FTECHING_VIDEO_ERROR = "FTECHING_VIDEO_ERROR";


export const getVideos = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST","/dataInfo","/studentVideos",courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: res.data.gatewayResponse.response })
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message});
        }

    } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}





