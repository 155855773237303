import React from 'react'

const AllowList = () => {
  return (
    <div>
      <h1>Allow list</h1>
    </div>
  )
}

export default AllowList
