import React from 'react'
import { connect } from 'react-redux'
import { Loader } from './loader'

export const LoadingScreen = (props) => {
    const { isLoading, loadingText } = props.commonReducer;
    return (
        <div className={`loading-screen ${isLoading ? "active" : ""}`}>
            <Loader loadingScreen={isLoading} loadingText={loadingText} />
        </div>
    )
}

const mapStateToProps = (state)=>({
    commonReducer: state.commonReducer
});

export default connect(mapStateToProps,null)(LoadingScreen);