import peaceImage from 'assets/peace.png'
import callImage from 'assets/Call.png'
import instagram from 'assets/instagram.png'
import facebook from 'assets/facebook.png'
import youtube from 'assets/Mask Group 7.png'
import telegram from 'assets/telegram (1).png'
import twitter from 'assets/Logo_of_Twitter.png'
import { Link } from 'react-router-dom'
import Logo from '../assets/White-logo.png'


export const Footer = () => {
    return (
        <div className="footer container-fluid">
            <div className='container d-md-flex text-center pt-3'>
                <div className='d-flex align-self-md-end justify-content-center'>
                    <img src={Logo} width="130" height="30"/>
                </div>
                    <div className='mt-5  flex-grow-1 d-md-flex justify-content-end'>
                    <div className='mx-3'><Link className="text-white" to='/about-us'>About Us</Link></div>
                        <div className='mx-3'><Link className="text-white" to='/blogs'>Blogs</Link></div>
                        <div className='mx-3'><Link className="text-white" to='/plans/ug-curriculum'>Plans</Link></div>
                        <div className='mx-3'><Link className="text-white" to='/faqs'>FAQs</Link></div>
                        <div className='mx-3'><Link className="text-white" to='/terms-conditions'>Terms & Conditions</Link></div>
                        <div className='mx-3'><Link className="text-white" to='/contact-us'>Contact Us</Link></div>
                        <div className='mx-3'><Link className="text-white" to='/privacy-policy'>Privacy Policy</Link></div>
                    </div>
                </div>
            <div className='container py-5 pt-md-5 pb-md-5'>
                <div className="row gap-2">
                    <div className="col-12 col-md-auto d-flex justify-content-center align-items-center">
                        <img src={peaceImage} alt='email' height='25' />
                        <a href="mailto:help@cuspyd.com" className='ms-4 text-light'>help@cuspyd.com</a>
                    </div>
                    <div className='col-12 col-md-auto flex-grow-1  d-flex justify-content-center align-items-center mb-md-0'>
                        {/* Follow us: &nbsp;&nbsp;&nbsp;&nbsp; */}
                        <div className='social-icon'>
                            <img src={instagram} onClick={()=>window.open("https://www.instagram.com/cuspyd/","_blank")} alt='instagram' />
                        </div>
                        <div className='social-icon ms-3'>
                            <img src={facebook} onClick={()=>window.open("https://www.facebook.com/cuspyd","_blank")} alt='facebook' />
                        </div>
                        <div className='social-icon ms-3'>
                            <img src={youtube} onClick={()=>window.open("https://www.youtube.com/@cuspyd","_blank")} alt='youtube' />
                        </div>
                        <div className='social-icon ms-3'>
                            <img src={twitter} onClick={()=>window.open("https://twitter.com/cuspyd_dental","_blank")} alt='telegram' />
                        </div>
                        <div className='social-icon ms-3'>
                            <img src={telegram} onClick={()=>window.open("https://t.me/+AGmkHLwdO3A5ZGRl","_blank")} alt='telegram' />
                        </div>
                    </div>
                    <div className='col-12 col-md-auto text-right align-items-center d-flex justify-content-center'>
                        <img src={callImage} alt='call icon' height='25' />
                        <span className='ms-3'>8877434567, 8877534567 (10 AM TO 7 PM)</span>
                    </div>
                </div>
            </div>
        </div>
    )
}