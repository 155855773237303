export const compose = (...validators) => (...values) => validators.reduce((error, validator) => error || validator(...values), undefined)
export const required = val => val ? '' : 'Field is required'
export const name = val => !val || /^[a-z\s]{3,45}$/ig.test(val) ? '' : '3 to 45 characters required. Alphabets and Space allowed.'
export const mobile = val => !val || /^[0-9]{10}$/ig.test(val.replace(/[\D]/g, '')) ? '' : 'Invalid mobile number'
export const mobile_state = val => !val || /^[0-9]{7,10}$/ig.test(val.replace(/[\D]/g, '')) ? '' : 'Invalid mobile number'
export const email = val => !val || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val) && !val.includes(" ") ? '' : 'Invalid email id'
export const password_old = val => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/.test(val)?'': 'Min length 6 with atleast 1 Caps, 1 Number & 1 Special character'
export const password = val =>    /^(?=.*[a-zA-Z0-9]).{6,20}$/.test(val)?'': ' Minimum 6 to Maximum 20 characters allowed '
export const phoneNumber1 = val => /^[0-9]{10}$/.test(val)?'':'Enter valid Phone Number';
export const zipcode = val => /^[1-9][0-9]{5}$/.test(val)?'':'Enter valid zipcode';

export const collge = val => val.length > 0 ? '' : 'Must be valid college';

export default {
    password: val => !val || /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/?'': 'Min length 6 with atleast 1 Caps, 1 Number & 1 Special character',
    gender: (val, optional = false) => (optional && !val) || ["Male", "female", "Other"].includes(val),
    dob: (val, optional = false) => (optional && !val) || val < new Date().valueOf(),
    city: val => val && val.length < 30,
    address: val => val && val.length < 100,
    state: val => val && val.length < 30,
    country: (val, optional) => (optional && !val) || val,
    links: (values, optional) => (optional && !values?.length) || Array.isArray(values) && values.every(val => val.name && val.url),
    zip: val => val && /[\d]{5,6}/.test(val),
    description: val => val && val.length && val.length < 1025,
    companies: (val, optional = false) => (optional && !val?.length) || Array.isArray(val) && val.every(item => item.name && item.website && item.description),
    number: (...values) => values.every(val => val && typeof val == 'number'),
    positiveNumber: function (...values) { return this.number(...values) && values.every(val => val > 0) },
    string: (...values) => values.every(val => val && typeof val === 'string'),
    eventTime: (...values) => values.every(val => val >= new Date().valueOf()),
    futureDate: val => val && val > new Date().valueOf()
}