import { axiosRequestSilent } from './../../apis/api-v1';
import  cookies  from 'react-cookies';
import { toast } from 'react-toastify';

export const resendEmailVerificationLink = async ()=>{
    const sessionToken = cookies.load("sessionToken");
    const studentId = parseInt(cookies.load("studentId"));
    if(!studentId || !sessionToken) {toast.error("something went wrong !");return};
    try {
        const studentId = parseInt(cookies.load("studentId"));
        const res = await axiosRequestSilent("POST","/student","/sendEmailVerificaitonToken",{studentId},{});
        if(res.data.gatewayResponse.status.isSuccess){
            toast.success("check your email.",{hideProgressBar:true});
        }else{
            toast.error("something went wrong.",{hideProgressBar: true});
        }
    } catch (error) {
        toast.error("something went wrong.",{hideProgressBar: true});
    }
}

export const deleteAccount = async ()=>{
    const sessionToken = cookies.load("sessionToken");
    const studentId = parseInt(cookies.load("studentId"));
    if(!studentId || !sessionToken) {toast.error("something went wrong !");return false};
    try {
        const confirm = window.confirm("Are you sure you want to delete your account ?");
        if(confirm){
            const studentId = parseInt(cookies.load("studentId"));
            const res = await axiosRequestSilent("POST","/student","/delete",{studentId},{});
            if(res.data.gatewayResponse.status.isSuccess){
                toast.info("Account deleted.");
                return true;
            }else{
                toast.error("something went wrong.");
                return false;
            }
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const uploadProfileImage = async (body)=>{
    const sessionToken = cookies.load("sessionToken");
    const studentId = parseInt(cookies.load("studentId"));
    if(!studentId || !sessionToken) {toast.error("something went wrong !");return false};
    try {
        const studentId = parseInt(cookies.load("studentId"));
        const res = await axiosRequestSilent("POST","/student","/imageUpload",{studentId,...body},{});
        if(res.data.gatewayResponse.status.isSuccess){
            toast.success("Image Uploaded.",{hideProgressBar: true});
            return true;
        }else{
            toast.error("something went wrong.",{hideProgressBar: true});
            return false;
        }
        
    } catch (error) {
        console.log(error);
        return false;
    }
}


export const getCountryStateCodes = async (courseId, countryCode = 91)=>{
    try {
        const res = await axiosRequestSilent("POST","/student","/getCountryStateCodes",{courseId, countryCode},{});
        if(res.data.gatewayResponse.status.isSuccess){
            return res.data.gatewayResponse.response;
        } else{
            return [];
        }
    } catch (error) {
        return [];
    }
}

export const getCollegeList = async (courseId, id, specialization)=>{
    try {
        const res = await axiosRequestSilent("POST","/student","/getColleges",{courseId, id , specialization },{});
        if(res.data.gatewayResponse.status.isSuccess){
            return res.data.gatewayResponse.response;
        } else{
            return [];
        }
    } catch (error) {
        return [];
    }
}