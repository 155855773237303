import { toast } from 'react-toastify';
import { axiosRequestSilent } from '../../apis/api-v1';

export const FTECHING_VIDEOS = "FTECHING_VIDEOS";
export const FTECHING_VIDEO_SUCCESS = "FTECHING_VIDEO_SUCCESS";
export const FTECHING_VIDEO_ERROR = "FTECHING_VIDEO_ERROR";
export const UPDATE_QUESTION_BOOKMARK = "UPDATE_QUESTION_BOOKMARK";

export const getQB_AnatomyExam = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    if (courseBody.customModule) {
        try {
            const res = await axiosRequestSilent("POST", "/qb", '/getCustomModuleQuestions', courseBody, {}, dispatch);
            if (res.data.gatewayResponse.status.isSuccess) {
                let base64ToString = Buffer.from(res.data.gatewayResponse.data, "base64").toString();
                base64ToString = JSON.parse(base64ToString);
                // console.log('base64ToString 1 ',base64ToString);
                dispatch({ type: FTECHING_VIDEO_SUCCESS, res: base64ToString })
            } else {
                dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message });
            }
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data) {
                dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
                return;
            }
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
        }
    } else if (courseBody.bookmarks) {
        try {
            const res = await axiosRequestSilent("POST", "/qb", "/getBookmarkedQuestionsInModule", courseBody, {}, dispatch);
            if (res.data.gatewayResponse.status.isSuccess) {
                let base64ToString = Buffer.from(res.data.gatewayResponse.data, "base64").toString();
                base64ToString = JSON.parse(base64ToString);
                localStorage.setItem('bookmarkQuestion',JSON.stringify(base64ToString))
                // console.log('base64ToString 1 ',base64ToString);
                dispatch({ type: FTECHING_VIDEO_SUCCESS, res: base64ToString })
            } else {
                dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message });
            }
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data) {
                dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
                return;
            }
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
        }
    } else {
        try {
            const res = await axiosRequestSilent("POST", "/qb", "/getAllQuestionsInModule", courseBody, {}, dispatch);
            if (res.data.gatewayResponse.status.isSuccess) {
                let base64ToString = Buffer.from(res.data.gatewayResponse.data, "base64").toString();
                base64ToString = JSON.parse(base64ToString);
                // console.log('base64ToString 1 ',base64ToString);
                dispatch({ type: FTECHING_VIDEO_SUCCESS, res: base64ToString })
            } else {
                dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message });
            }
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data) {
                dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
                return;
            }
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
        }
    }

}

export const bookmarkQuestion = (body, callback) => async (dispatch) => {
    try {
        const res = await axiosRequestSilent("POST", "/qb", body.bookmarked ? "/addBookmark" : "/removeBookmark", body, {}, dispatch);
        if(res.data?.gatewayResponse?.status?.isSuccess) {
            callback?.(false)
            // toast.success("successfully") ; 
            dispatch({ type: UPDATE_QUESTION_BOOKMARK, payload: body })
        } else{
            callback?.(true)
            // toast.error(res.data?.gatewayResponse?.status?.message);
        }
    } catch (error) {
        toast.error("Something went wrong");
    }
}

export const submitQB_Anatomys = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_VIDEOS });
    try {
        const res = await axiosRequestSilent("POST", "/qb", "/submitModule", courseBody, {}, dispatch);
        if(res.data.gatewayResponse.status.isSuccess) {

            dispatch({ type: FTECHING_VIDEO_SUCCESS, res: res.data.gatewayResponse.data })
        }else{
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: res.data.gatewayResponse.status.message });
        }
     

    } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_VIDEO_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}






