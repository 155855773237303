import { axiosRequestNA, axiosRequestSilent, axiosRequestSilentPublic } from './../apis/api-v1';
import cookies from 'react-cookies'
import axios from 'axios';
import { toast } from 'react-toastify';


export const AUTHENTICATING_USER = "AUTHENTICATING_USER";
export const AUTHENTICATING_USER_SUCCESS = "AUTHENTICATING_USER_SUCCESS";
export const AUTHENTICATING_USER_ERROR = "AUTHENTICATING_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_USER = 'UPDATE_USER';


export const signin = (signInBody, callback) => async (dispatch) => {
    dispatch({ type: AUTHENTICATING_USER });
    try {
        const res = await axiosRequestNA("POST", "/student", "/login", signInBody, {}, dispatch);
        if (res.data.gatewayResponse.status.isSuccess) {
            dispatch({ type: AUTHENTICATING_USER_SUCCESS, res: res.data.gatewayResponse.response })
            const sessionToken = res.data.gatewayResponse.response.sessionToken;
            const refreshToken = res.data.gatewayResponse.response.refreshToken;
            const studentId = res.data.gatewayResponse.response.studentId;
            cookies.save("studentId", studentId);
            cookies.save('sessionToken', sessionToken);
            cookies.save('refreshToken', refreshToken);

            const localStoragedata = {
                studentId: studentId,
                sessionToken: sessionToken,
                refreshToken: refreshToken,
                loginpath: '/dashboard'
            }
            localStorage.setItem('localStoragedata', JSON.stringify(localStoragedata));
            callback?.(true)
        } else {
            dispatch({ type: AUTHENTICATING_USER_ERROR, res: res.data.gatewayResponse.status.message });
        }
    } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
            dispatch({ type: AUTHENTICATING_USER_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: AUTHENTICATING_USER_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

export const addUser = (userBody) => async (dispatch) => {
    try {
        const res = await axiosRequestNA("POST", "/student", "/signup", userBody, {}, dispatch);
        if (res.data.gatewayResponse.status.isSuccess) {
            return {
                success: true,
                data: res.data.gatewayResponse.response
            }
        } else {
            return {
                success: false,
                data: null
            }
        }
    } catch (error) {
        console.log(error);

    }
}

export const sendOTP = async (body) => {
    try {
        const res = await axiosRequestSilentPublic("POST", "/student", "/login/sendOtp", body, {});
        if (res.data.gatewayResponse.status.isSuccess) {
            toast.success("otp sent successfully");
            return res.data.gatewayResponse;
        } else {
            toast.error(res.data.gatewayResponse.status.message);
            return res.data.gatewayResponse;
        }
    } catch (error) {
        throw new Error("Could not send Otp");
    }
}

export const verifyOTPLogin = (otpBody, callback) => async (dispatch) => {
    dispatch({ type: AUTHENTICATING_USER });
    try {
        const res = await axiosRequestNA("POST", "/student", "/login/verifyOtp", otpBody, {}, dispatch);
        if (res.data.gatewayResponse.status.isSuccess) {
            dispatch({ type: AUTHENTICATING_USER_SUCCESS, res: res.data.gatewayResponse.response })
            const sessionToken = res.data.gatewayResponse.response.sessionToken;
            const refreshToken = res.data.gatewayResponse.response.refreshToken;
            const studentId = res.data.gatewayResponse.response.studentId;
            cookies.save('studentId', studentId);
            cookies.save('sessionToken', sessionToken);
            cookies.save('refreshToken', refreshToken);
            const localStoragedata = {
                studentId: studentId,
                sessionToken: sessionToken,
                refreshToken: refreshToken,
                loginpath: '/dashboard'
            }
            localStorage.setItem('localStoragedata', JSON.stringify(localStoragedata));
            callback?.(true)
        } else {
            dispatch({ type: AUTHENTICATING_USER_ERROR, res: res.data.gatewayResponse.status.message });
        }
    } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
            dispatch({ type: AUTHENTICATING_USER_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: AUTHENTICATING_USER_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}



export const getGEOLocation = async () => {
    try {
        const response = await axios.get("https://api.ipify.org?format=json");
        const { ip } = response.data;
        const res = await axiosRequestSilentPublic("POST", "/student", "/geo", { geo: ip }, {});
        return res;
    } catch (error) {
        console.log(error);
        throw new Error("could not send otp");
    }
}

export const verifyOTP = (otpBody) => async (dispatch) => {
    dispatch({ type: AUTHENTICATING_USER });
    try {
        const res = await axiosRequestNA("POST", "/student", "/signup/verifyOtp", otpBody, {}, dispatch);
        if (res.data.gatewayResponse.status.isSuccess) {
            dispatch({ type: AUTHENTICATING_USER_SUCCESS, res: res.data.gatewayResponse.response })
            const sessionToken = res.data.gatewayResponse.response.sessionToken;
            const refreshToken = res.data.gatewayResponse.response.refreshToken;
            const studentId = res.data.gatewayResponse.response.studentId;
            cookies.save('studentId', studentId);
            cookies.save('sessionToken', sessionToken);
            cookies.save('refreshToken', refreshToken);

            const localStoragedata = {
                studentId: studentId,
                sessionToken: sessionToken,
                refreshToken: refreshToken,
                loginpath: '/dashboard'
            }
            localStorage.setItem('localStoragedata', JSON.stringify(localStoragedata));
        } else {
            dispatch({ type: AUTHENTICATING_USER_ERROR, res: res.data.gatewayResponse.status.message });
        }
        return { success: res.data?.gatewayResponse?.status?.isSuccess, msg: res.data?.gatewayResponse?.status?.message }
    } catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: AUTHENTICATING_USER_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: AUTHENTICATING_USER_ERROR, errMsg: "Could not fetch post something went wrong !" });
        return { success: false, msg: error?.response?.data }
    }
}

export const syncProfile = () => async (dispatch) => {

    // check if all the data are there in cookies;
    const sessionToken = cookies.load("sessionToken");
    const studentId = parseInt(cookies.load("studentId"));
    if (!studentId || !sessionToken) return;
    try {
        const res = await axiosRequestSilent("POST", "/student", "/getStudentById", { studentId }, {});
        if (res.data.gatewayResponse.status.isSuccess) {
            if (res.data.gatewayResponse.status.statusCode == 'DT914') {
                window.location.reload(true);
            }
            dispatch({ type: AUTHENTICATING_USER_SUCCESS, res: res.data.gatewayResponse.response })
            return;
        } else {
            dispatch({ type: AUTHENTICATING_USER_ERROR, res: res.data.gatewayResponse.status.message });
            return;
        }
    } catch (error) {
        console.log('server down ', error)
        if (error.response && error.response.data) {
            dispatch({ type: AUTHENTICATING_USER_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: AUTHENTICATING_USER_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}



export const SubmitLead = (userBody) => async (dispatch) => {
    try {
        const res = await axiosRequestNA("POST", "/dataInfo", "/postWebLead", userBody, {}, dispatch);
        if (res.data.gatewayResponse.status.isSuccess) {
            return {
                success: true,
                data: res.data.gatewayResponse.response
            }
        } else {
            return {
                success: false,
                data: null
            }
        }
    } catch (error) {
        console.log(error);

    }
}

export const LeadVerifyOTP = (userBody) => async (dispatch) => {
    try {
        const res = await axiosRequestNA("POST", "/dataInfo", "/leadVerifyOTP", userBody, {}, dispatch);
        if (res.data.gatewayResponse.status.isSuccess) {
            return {
                success: true,
                data: res.data.gatewayResponse.response
            }
        } else {
            return {
                success: false,
                data: null
            }
        }
    } catch (error) {
        console.log(error);

    }
}


export const tryToAuthenticate = () => async (dispatch) => {
    var sessionToken = cookies.load("sessionToken");
    var studentId = parseInt(cookies.load("studentId"));
    var refreshToken = cookies.load("refreshToken");

    if (!sessionToken) {
        const tryToAuthenticate1 = JSON.parse(localStorage.getItem('localStoragedata'))
        //    console.log('tryToAuthenticate1 123' , tryToAuthenticate1)
        if (tryToAuthenticate1 !== null) {
            sessionToken = tryToAuthenticate1.sessionToken;
            studentId = parseInt(tryToAuthenticate1.studentId);
            refreshToken = parseInt(tryToAuthenticate1.refreshToken);
            cookies.save('studentId', studentId);
            cookies.save('sessionToken', sessionToken);
            cookies.save('refreshToken', refreshToken);
        }
    }

    if (!studentId || !sessionToken)return false;
    dispatch({ type: AUTHENTICATING_USER });
    try {
        const res = await axiosRequestSilent("POST", "/student", "/getStudentById", { studentId }, {});
        if (res.data.gatewayResponse.status.isSuccess) {
            dispatch({ type: AUTHENTICATING_USER_SUCCESS, res: res.data.gatewayResponse.response })
            return true;
        } else {
            dispatch({ type: AUTHENTICATING_USER_ERROR, res: res.data.gatewayResponse.status.message });
            return false;
        }
    } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
            dispatch({ type: AUTHENTICATING_USER_ERROR, errMsg: error?.response?.data });
            return false;
        }
        dispatch({ type: AUTHENTICATING_USER_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }

}

export const updateAuthUserInfo = (payload = {}) => dispatch => {
    dispatch({ type: UPDATE_USER, payload })
}
export const logout = () => async (dispatch) => {
    // let cond = await window.confirm("Are you sure you want to logout ?");
    // if(!cond) return;
    dispatch({ type: AUTHENTICATING_USER });
    const logoutBody = {
        studentId: parseInt(cookies.load("studentId"))
    }
    await axiosRequestSilent("POST", "/student", "/logout", logoutBody, {});
    toast.success("Logged out successfully");
    localStorage.removeItem("emailVerificationLinkSend");
    localStorage.removeItem("prevPath");
    cookies.remove("sessionToken");
    cookies.remove("studentId");
    cookies.remove("refreshToken");
    localStorage.removeItem('localStoragedata');
    localStorage.clear();
    dispatch({ type: LOGOUT_USER });

    // window.location.reload(true);
    // window.location.href = '/';    
}

export const forgotPassword = async (body) => {
    try {
        const res = await axiosRequestSilent("POST", "/student", "/forgotPassword", body, {});
        if (res.data.gatewayResponse.status.isSuccess) {
            toast.success("OTP Sent", { hideProgressBar: true });
            return res.data.gatewayResponse.response;
        } else {
            toast.error(res.data.gatewayResponse.status.message, { hideProgressBar: true });
            return false;
        }
    } catch (error) {
        console.log(error);
        toast.error("Something Went Wrong ! ", { hideProgressBar: true });
        return false;
    }
}

export const resendOTPFOrSignup = async (body) => {
    try {
        const res = await axiosRequestSilent("POST", "/student", "/signupResendOTP", body, {});
        if (res.data.gatewayResponse.status.isSuccess) {
            toast.success("OTP Sent", { hideProgressBar: true });
            return res.data.gatewayResponse.response;
        } else {
            toast.error(res.data.gatewayResponse.status.message, { hideProgressBar: true });
            return false;
        }
    } catch (error) {
        console.log(error);
        toast.error("Something Went Wrong ! ", { hideProgressBar: true });
        return false;
    }
}

export const resetPassword = async (body) => {
    try {
        const res = await axiosRequestSilent("POST", "/student", "/resetPassword", body, {});
        if (res.data.gatewayResponse.status.isSuccess) {
            toast.success("Password Changed ", { hideProgressBar: true });
            return true;
        } else {
            toast.error(res.data.gatewayResponse.status.message, { hideProgressBar: true });
            return false;
        }
    } catch (error) {
        console.log(error);
        toast.error("Something Went Wrong ! ", { hideProgressBar: true });
        return false;
    }
}