import { axiosRequestSilent, axiosRequestSilentPublic } from './../apis/api-v1';

export const FTECHING = "FTECHING";
export const FTECHING_SUCCESS = "FTECHING_SUCCESS";
export const FTECHING_ERROR = "FTECHING_ERROR";


export const getBlogInfo = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING });
    try {
        const res = await axiosRequestSilentPublic("POST","/dataInfo","/getWebBlogs",courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            dispatch({ type: FTECHING_SUCCESS, res:res.data.gatewayResponse.response })
        }else{
            dispatch({ type: FTECHING_ERROR, errMsg:res.data.gatewayResponse.status.message});
        }
    } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}





