import { Suspense, useEffect, lazy } from "react";
import {
  Switch,
  useLocation,
  Route,
  Redirect as ReactRedirect,
} from "react-router-dom";
import Home from "pages/Home";
import { Login } from "pages/Login";
import { Signup } from "pages/Signup";
import { Footer } from "./footer";
import Header from "./Header";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import VerifyEmail from "./VerifyEmail";
import AllowList from "./AllowList";
import Redirect from "./Redirect";
import instagram from "assets/instagram.png";
import facebook from "assets/facebook.png";
import youtube from "assets/Mask Group 7.png";
import telegram from "assets/telegram (1).png";
import twitter from "assets/Logo_of_Twitter.png";
import { Toast } from "bootstrap";
// import Blog from './../pages/blog/Main'
const Blog = lazy(() => import("./../pages/blog/Main"));
const IndividulaBlog = lazy(() =>
  import("pages/blog/component/IndividulaBlog")
);
const Dashboard = lazy(() => import("pages/Dashboard"));
const FAQs = lazy(() => import("pages/FAQs"));
const Terms_Conditions = lazy(() => import("pages/Terms_Conditions"));
const Course = lazy(() => import("pages/Course"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"));
const UploadImageModal = lazy(() => import("./UploadImageModal"));
const Plan = lazy(() => import("pages/Dashboard/Plan"));
const About = lazy(() => import("pages/About"));

export const Router = ({ history }) => {
  const location = useLocation();
  const isDashboardOpen = location?.pathname?.includes("/dashboard");
  useEffect(() => {
    // if (!window.navigation?.canGoForward) window.scrollTo(0, 0)
  }, [location?.pathname]);

  return (
    <>
      <div className={history?.location?.pathname == "/" ? "bg-main" : ""}>
        <div
        //   className="social-icons-box d-none d-sm-flex d-md-flex"
          className={`social-icons-box ${location?.pathname == "/" ? 'd-none d-sm-flex d-md-flex' : 'd-none'}`}
        //   style={{ display: location?.pathname == "/" ? "flex" : "none" }}
        >
          <img
            className="pointer"
            onClick={() =>
              window.open("https://www.instagram.com/cuspyd", "_blank")
            }
            src={instagram}
            height="20"
            alt="instagram"
          />
          <img
            className="pointer"
            onClick={() =>
              window.open("https://www.facebook.com/cuspyd", "_blank")
            }
            src={facebook}
            height="30"
            alt="facebook"
          />
          <img
            className="pointer"
            onClick={() =>
              window.open("https://www.youtube.com/@cuspyd", "_blank")
            }
            src={youtube}
            height="20"
            alt="youtube"
          />
          <img
            className="pointer"
            onClick={() =>
              window.open("https://twitter.com/cuspyd_dental", "_blank")
            }
            src={twitter}
            height="20"
            alt="telegram"
          />
          <img
            className="pointer"
            onClick={() =>
              window.open("https://t.me/+AGmkHLwdO3A5ZGRl", "_blank")
            }
            src={telegram}
            height="23"
            alt="telegram"
          />
        </div>
        <Header />
        <div
          className={`${
            isDashboardOpen ? "container-fluid pl-0" : "container pb-5"
          }`}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <ReactRedirect from="/neet-pg/1" to="/course/neet-pg" />
              <ReactRedirect from="/neet-ss/2" to="/course/neet-ss" />
              <ReactRedirect from="/tnc" to="/terms-conditions" />
              <ReactRedirect
                from="/blogs/28"
                to="/blogs/23/coaching-in-medical-education-enhanced-by-doctutorials-app-v-2.023"
              />
              <ReactRedirect
                from="/blogs/30"
                to="/blogs/22/medical-teaching-methods-get-a-boost-via-doctutorials-app-v-2.023"
              />
              <ReactRedirect
                from="/blogs/31"
                to="/blogs/20/the-future-of-medical-education-inspires-the-doctutorials-app-v-2.023"
              />
              <Route exact sensitive path="/course/NEET-PG">
                <ReactRedirect to="/course/neet-pg" />
              </Route>
              <Route exact path="/course/NEET SS">
                <ReactRedirect to="/course/neet-ss" />
              </Route>
              <Route exact path="/course/NEET PG">
                <ReactRedirect to="/course/neet-pg" />
              </Route>
              <Route exact sensitive path="/course/FMGE">
                <ReactRedirect to="/course/fmge" />
              </Route>

              <PublicRoute exact path="/" component={Home} />
              <PublicRoute exact path="/about-us" component={About} />
              <Route exact path="/about">
                <ReactRedirect to="/about-us" />
              </Route>
              <PrivateRoute exact path="/plans" component={Plan} showLogin={true}/>
              <PrivateRoute exact path="/plans/:courseName"  component={Plan} loginRedirect="&plans=true" showLogin={true} />
              <PublicRoute
                path="/student/verify/:tokenId"
                component={VerifyEmail}
              />
              <PublicRoute path="/course/:courseName" component={Course} />
              <PublicRoute path="/blogs/:id" component={IndividulaBlog} />
              <PublicRoute path="/blogs" component={Blog} />
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <PublicRoute path="/faqs" component={FAQs} />
              <PublicRoute path="/allowlist" component={AllowList} />
              <PublicRoute path="/redirect" component={Redirect} />

              <PublicRoute path="/reviews" component={Home} />
              <PublicRoute path="/contact-us" component={Home} />
              {/* <PublicRoute path='/about' component={Home} /> */}

              <PublicRoute
                path="/terms-conditions"
                component={Terms_Conditions}
              />
              <PublicRoute path="/privacy-policy" component={PrivacyPolicy} />
              <PublicRoute path="/upload" component={UploadImageModal} />
              {/* <Redirect exact to='/' /> */}
            </Switch>
          </Suspense>
          <Login />
          <Signup />
        </div>
        {!isDashboardOpen && <Footer />}
      </div>
    </>
  );
};

export default Router;
