export const phone = val => {
    if (val) {
        if (!val.includes('XXXXXX')) val = val.replace(/^0|\D/g, '')
        if (val.length == 10) return `(${val.slice(0, 3)}) ${val.slice(3, 6)}-${val.slice(6)}`
        else return val
    } else return val
}
export const curruncy = (val, rupSign) => {
    let result = val ? new Intl.NumberFormat('en-IN', {}).format(val.replace(/[\D]/g, '')) : val
    return rupSign == true ? '₹'.concat(result) : result
}
export const number = val => {
    if (val && typeof val == 'string') return val.replace(/[\D]/g, '')
    else return val
}
export const url = (val) => {
    if (val) return val.replace(/\s/g, '').toLowerCase()
    else return val
}