import * as commonActions from 'store/actions/common'

export const BASE_URL = process.env.REACT_APP_NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL_API_PROD : process.env.REACT_APP_BASE_URL_API_DEV


const callApi = (url, path, method, body = {}, config = { headers: {} }) => {	
	const params = {
		method,
		...config,
		headers: {
			"apiversion": "1.0.0.0",
			"appversion": "1.0.0.0",
			"channelid": "App",
			'Content-Type':'application/json',
			'SerreqId': url + path,
			...config.headers
		},
		body: JSON.stringify({
			gatewayRequest: {
				request: {
					...body,
				}
			}
		}),
		mode: 'no-cors'
	}
	if (method === 'GET') {
		delete params.data
	}

	return (dispatch, loader = true, notification = false, timeout = false) => {
		console.log("Calling me");

		/** Show Loader */
		if (loader) dispatch(commonActions.loader(true))

		/** Call the API */
		fetch(BASE_URL + url, params)
			.finally(() => {
				if (loader) dispatch(commonActions.loader(false))
				console.log("Calling me");
			})
			.then((res) => {
				console.log('PPPPPPPP', res)
				/** Hide Loader */
				if (res.ok) return res.json()
				else throw new Error(res.statusCode === 500 ? 'Something went wrong! Please try again leter' : res.statusCode === 429 ? 'Too many requests! Plaese try again leter' : res.statusText)
			})
			.then(res => {
				console.log('RESPONSE', res)
				// if (res.success) {
				// 	if (notification && res.status) dispatch(commonActions.notify(false, res.status))
					return res
				// }
				// else throw new Error(res.status)
			})
			.catch(err => {
				console.log('ERROR', err)
				/** Hide Loader */
				// dispatch(commonActions.notify(true, err.message))
				// if (err?.response?.status === 403 || err?.response?.status === 401) {
				// 	if (process.env.NODE_ENV !== 'development') dispatch(logout())
				// }
				throw new Error(err.message)
			})
	}
}

export const get = (url, path, config) => callApi(url, path, 'GET', {}, config)
export const post = (url, path, body, config) => callApi(url, path, 'POST', body, config)
export const put = (url, path, body, config) => callApi(url, path, 'PUT', body, config)
export const del = (url, path, body, config) => callApi(url, path, 'DELETE', body, config)
