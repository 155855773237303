import { Button } from "reactstrap"
import objImage from 'assets/Get-in-Touch.png'
import { useState } from "react"
import { Form, Field } from 'react-final-form'
import { V, P } from 'utils'
import { axiosRequestSilent } from 'store/apis/api-v1';
import { toast } from 'react-toastify';
import country from './../assets/json/country.json';
import { Input } from "components/Input";
import { phoneNumber1 } from "utils/validators";
import { yearCourseMap } from "utils/methods"

export const GetInTouch = (props) => {
    const [field, setField] = useState({ name: "", email: "", message: "", mobile: "" });
    const [error, setError] = useState({ name: "", email: "", message: "", mobile: "" });
    const [state, setState] = useState({ code: 91, name: "", email: "", message: "", mobile: "", otpToken: '', leadId: '' })

    const [isloadingotp, setisloadingotp] = useState(0)

    const [otpToken, setOtpToken] = useState(0);
    const [leadId, setLeadId] = useState(0);

    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);


    const [formState, setFormState] = useState({
        studentId: props.authState?.profileInfo?.studentId,
        currCountryCode: props.authState?.profileInfo?.countryCode,
        currMobileNumber: props.authState?.profileInfo?.mobileNo,
        newMobileNo: "",
        newCountryCode: 91,
        otp: "",
        otpToken: "",
        leadId: ''
    })

    const inputfocus = (elmnt) => {
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {

                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            const next = elmnt.target.tabIndex;
            if (next < 6) {
                elmnt.target.form.elements[next].focus()
            }
        }

    }
    const handleSubmit = async values => {
        if (step == 0) {
            var codes = values.code
            if (codes) {
                codes = parseInt(values.code)
            } else {
                codes = values.state.code
            }
            try {
                const res = await axiosRequestSilent("POST", "/dataInfo", "/postWebLead",
                    {
                        countryCode: codes,
                        name: values.name,
                        email: values.email,
                        mobileNo: values.mobile,
                        courseName: values.course,
                        message: values.message
                    }
                )
                if (res.data?.gatewayResponse?.status?.isSuccess) {
                    toast.success("OTP sent to current number.");
                    setFormState({
                        ...formState,
                        otpToken: res.data?.gatewayResponse.response.otpToken,
                        leadId: res.data?.gatewayResponse.response.leadId
                    })
                    setStep(1);
                    setOtpToken(res.data?.gatewayResponse.response.otpToken);
                    setLeadId(res.data?.gatewayResponse.response.leadId);
                } else {
                    toast.error(res.data?.gatewayResponse?.status?.message);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        } else {
            try {
                const res = await axiosRequestSilent("POST", "/dataInfo", "/leadVerifyOTP",
                    {
                        otp: new Array(6).fill(0).map((_, i) => values[`otp_${i}`]).join(""),
                        otpToken: otpToken,
                        leadId: leadId
                    }
                )
                if (res.data?.gatewayResponse?.status?.isSuccess) {
                    toast.success(res.data?.gatewayResponse?.status?.message);
                    setStep(0);
                } else {
                    toast.error(res.data?.gatewayResponse?.status?.message);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
            //  console.log('testing1' , testing )
        }

    }

    return (
        <div  id="contact-us" className="pt-5">
            <h1 className='text-center font-medium' id="get-in-touch">Get in Touch</h1>
            <div className='row mt-5'>
                <div className='col-12 col-md-4 m-auto'>
                    <div className='p-2'>
                        <img src={objImage} alt='email' className='img-fluid' />
                    </div>
                </div>
                <div className='col-12 col-md-7 m-auto align-self-center'>
                    <div className="pd-md-5">
                        {step == 1 ? '' :
                            <Form onSubmit={handleSubmit} initialValues={{ state }} render={({ handleSubmit }) => (<>
                                <form onSubmit={handleSubmit} className='mt-5 row justify-content-center'>
                                    <div className="row">
                                        <div className='col-12 col-md-5'>
                                            <Field name={"name"} validate={V.compose(V.required, V.name)} >
                                                {({ input, meta }) => (
                                                    <Input {...input} {...meta} name={"name"} placeholder='Name*' inputClassName='shadow border-none' />
                                                )}
                                            </Field>
                                        </div>
                                        <div className='col-12 col-md-5 mt-4 mt-md-0'>
                                            <Field name={"email"} validate={V.compose(V.required, V.email)} >
                                                {({ input, meta }) => (
                                                    <Input {...input} {...meta} name={"email"} placeholder='Email*' inputClassName='shadow border-none' />
                                                )}
                                            </Field>
                                        </div>
                                        <div className="col-12 col-md-5 mt-4">
                                            <div className="row">
                                                <Field name='code' >
                                                    {({ input, meta }) => (
                                                        <Input {...input} {...meta} type='select' className="d-inline-flex col-4  pr-0" inputClassName='shadow border-none'>
                                                            <option value={"91"}>+91</option>
                                                            {Object.keys(country).sort((a, b) => a - b).map((item) => (
                                                                <option value={country[item][1]}>
                                                                    +{country[item][1]}
                                                                </option>))}
                                                        </Input>
                                                    )}
                                                </Field>
                                                <Field name='mobile' validate={V.compose(V.required, V.mobile_state)}>
                                                    {({ input, meta }) => (
                                                        <Input {...input} {...meta} inputmode="numeric" onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}className="col" inputClassName='shadow border-none'
                                                            placeholder="Enter phone number*" maxLength={10} />
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-5 mt-4'>
                                            <Field name={"course"} validate={V.compose(V.required)} >
                                                {({ input, meta }) => (
                                                    <Input {...input} {...meta} type='select' placeholder='Select your course*' inputClassName='shadow border-none' >
                                                        <option value={''}>Select your course*</option>
                                                        {yearCourseMap("UG CURRICULUM","IN").map((course, i) => <option value={course} key={i}>{course}</option>)}
                                                    </Input>
                                                )}
                                            </Field>
                                        </div>
                                        <div className='col-12 col-md-10 mt-4 mt-md-3'>
                                            <Field name={"message"} validate={V.compose(V.required)}>
                                                {({ input, meta }) => (
                                                    <Input {...input} {...meta} name={"message"} type='textarea' rows={4} placeholder='Leave a message' inputClassName='shadow border-none' ></Input>
                                                )}
                                            </Field>
                                        </div>
                                        <div className="row">
                                            <div className="mt-5 col-12 col-md-10 d-flex justify-content-end">
                                                <Button type="submit" color='blue'>SEND</Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </>)} />
                        }
                        {step == 0 ? '' :
                            <Form onSubmit={handleSubmit} initialValues={state} render={({ handleSubmit }) => (<>
                                <form onSubmit={handleSubmit} className='mt-5 row justify-content-center'>
                                    <div className="col-12 text-center mb-5">
                                        Please enter your OTP
                                    </div>
                                    {new Array(4).fill(0).map((_, i) => (
                                        <Field name={`otp_${i}`} parse={P.number} >
                                            {({ input, meta }) => (
                                                <Input {...input} tabIndex={i + 1} onKeyUp={e => inputfocus(e)} {...meta} type='text' className='col-auto' inputClassName={`otp-input text-center ${input.value ? 'border-orange' : 'border-black'}`} maxLength={1} />
                                            )}
                                        </Field>
                                    ))}
                                    <div className="mt-5 text-center">
                                        <Button color="light" type="submit" size="lg">SUBMIT</Button>
                                    </div>
                                </form>
                            </>)} />
                        }

                    </div>
                </div>

            </div>
        </div>
    )
}