import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { tryToAuthenticate } from 'store/authentication/action';
import LoadingScreen from './loading-screen';

const PrivateRoute = ({ component: Component, authState, tryTologin,...rest }) => {
    const {isAuthenticated, authenticationInProgress } = authState;
    const { showLogin, loginRedirect } = rest;
    return (
        <Route
            {...rest}
            render={props=> localStorage.getItem('localStoragedata') ? <Component {...props} /> : authenticationInProgress && !!localStorage.getItem('localStoragedata') ? <LoadingScreen /> : showLogin? <Redirect to={`/?login=true${loginRedirect}`} /> : <Redirect to="/" />
            }
        />
    )
};
const mapStateToProps = (state) => ({
    authState: state.authReducer,
});
const mapDispatchToProps = (dispatch)=>({
    tryTologin: ()=> dispatch(tryToAuthenticate())
})
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);

// render={props => (authenticationInProgress) ? <h3 className='text-center'><b>Loading...</b></h3>
//             : !isAuthenticated ? <Redirect to="/" /> : <Component {...props} />
//             }