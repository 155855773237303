import { LOADING_HOME, LOADING_HOME_SUCCESS, LOADING_HOME_ERROR } from './action';

export const initialState = {
    loadingHomeInProgress: false,
    homeData: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_HOME: {
            return {
                ...state,
                loadingHomeInProgress: true,
            }
        }
        case LOADING_HOME_SUCCESS: {
            return {
                ...state,
                loadingHomeInProgress: false,
                homeData: action.res,
            }
        }
        case LOADING_HOME_ERROR: {
            return {
                ...state,
                loadingHomeInProgress: false,
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}
export default reducer;