import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const CookiesAcceptanceTab = () => {
  const location = useLocation();
  const [showAcceptance, setShowAcceptance] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    const isHomePage = location.pathname === '/';
    if (isHomePage && cookiesAccepted === null) {
      setShowAcceptance(true);
    } else {
      setShowAcceptance(false); // Hide the acceptance tab when the URL changes
    }
  }, [location]);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowAcceptance(false);
  };

  if (showAcceptance) {
    return (
      <div className="cookies-acceptance-tab">
        <div className='cookie-text'>
          This website uses cookies to enhance your browsing experience. By continuing to use this site, you consent to the use of cookies.
        </div>
        <div className='button-box'>
          <button onClick={handleAcceptCookies} className='accept-button'>Accept</button>
          <button onClick={handleAcceptCookies} className='necessary-button'>Necessary Only</button>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default CookiesAcceptanceTab;
