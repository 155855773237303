import { combineReducers } from 'redux';
import commonReducer from './reducers/common';
import authReducer from './authentication/reducer';
import homeReducer from './home/reducer';
import courseReducer from './course/reducer';
import dashBoardVideoReducer from './dashbaord/video/reducer';
import dashBoardQBReducer from './dashbaord/questionBank/reducer';
import testSeriesQBReducer from './dashbaord/testSeries/reducer';
import leaderBoardQBReducer from './dashbaord/leaderBoard/reducer';
import dashBoardAnatomyReducer from './dashbaord/anatomy/reducer';
import dashBoardAnatomyExamReducer from './dashbaord/anatomyExam/reducer';
import blogReducer from './blogs/reducer';

const reducer = {
    authReducer,
    commonReducer,
    homeReducer,
    courseReducer,
    dashBoardVideoReducer,
    dashBoardQBReducer,
    dashBoardAnatomyReducer,
    dashBoardAnatomyExamReducer,
    blogReducer,
    testSeriesQBReducer,
    leaderBoardQBReducer,
};
export const rootReducer = combineReducers(reducer);