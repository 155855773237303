import playButton from "assets/Play.png";
import QRCode from "./../assets/QRCode.png";
import planImage from "assets/Comprehensive.png";
import reviseImage from "assets/card-vector-01.png";
import preparationImage from "assets/Comprehensive-Qbank.png";
import facultyTeam from "assets/faculty-team.jpg";
import mcqVideos from "assets/Flashcards.png";
import quickVideos from "assets/Multiple-Choice-Questions.png";
import mcqCore from "assets/Structured-Notes.png";
import learnImage from "assets/Group 1052.png";
import understandImage from "assets/Group 1112.png";
import achieveImage from "assets/Group 1760.png";
import notesImage from "assets/Notes2.png";
import { GetInTouch } from "components/GetInTouchForn";
import { Plans } from "components/Plans";
import { DocTutorialCommunity } from "components/DocTutorialCommunity";
import { useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { loadGeneral, loadAuthenticated } from "./../store/home/action";
import { useState } from "react";
import VideoModal from "components/VideoModal";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { axiosRequestSilentPublic } from "store/apis/api-v1";
import {
  LOADING_HOME_ERROR,
  LOADING_HOME_SUCCESS,
} from "store_old/home/action";
import { toast } from "react-toastify";
import ReactPlayer from "react-player";
import { Carousel } from "react-responsive-carousel";
import { Helmet } from "react-helmet";
import LiveTestsPopUp from "components/LiveTestsPopUp";
import instagram from "assets/instagram.png";
import facebook from "assets/facebook.png";
import youtube from "assets/Mask Group 7.png";
import telegram from "assets/telegram (1).png";

var moduleids = 0;

const whyDoctutorial = [
  {
    title: "Effective & Dynamic 3D videos",
    image: planImage,
    description:
      "With our engaging, lively 3D visuals and easy-to-follow videos, you'll be able to quickly and confidently retain the concepts from each topic better & get a headstart on your BDS/BDS NExT preparations. Our expert faculty members present complex medical & dental concepts in a simple and understandable way.",
  },
  {
    title: "Structured Notes",
    image: quickVideos,
    description:
      " Maximize your exam preparation with our comprehensive notes covering all the key concepts and previously asked questions you need to know for the exam.Our pointwise notes, having tables, flowcharts, integrated mnemonics and easy-to-draw illustrations, give you an edge on exam day.",
  },
  {
    title: "Comprehensive Qbank",
    image: preparationImage,
    description:
      "Boost your confidence with our extensive collection of previously asked questions from various university exams. Use our collection of previously asked questions to take your preparation to the next level. It is the perfect resource for BDS and BDS NExT exam preparation.",
  },
  {
    title: "Multiple Choice Questions",
    image: mcqCore,
    description:
      "Self assess your knowledge by answering MCQs curated exclusively from standard textbooks and previous competitive exams after every topic for your success in NEET MDS.",
  },
  {
    title: "Flashcards",
    image: mcqVideos,
    description:
      "Our handpicked flashcards provide a concise and effective way for students to review high yield topics,important values and mnemonics in a short amount of time. This makes it an invaluable resource for busy students before exams.",
  },
  {
    title: "Experienced Faculty",
    image: facultyTeam,
    description:
      "Within our platform, you can benefit from the guidance of proficient faculty members, well-versed in their subjects. Their commitment to delivering top-quality classes is paralleled by their devotion to mentoring you, assuring thorough resolution of all your doubts. This approach ensures a holistic learning experience.",
  },
];

const yourJourney = [
  {
    title: "Learn",
    image: learnImage,
    description:
      "Get your basics strong from the core fundamentals, learn from our Video lectures, MCQs , Question bank, structured notes and flashcards . Build the foundation to a successful dental career.",
  },
  {
    title: "Understand",
    image: understandImage,
    description:
      "Commit yourself into exploring every aspect of your dental curriculum and understand better. Cuspyd will be your companion from classrooms to dental clinics, anytime, anywhere.",
  },
  {
    title: "Revise",
    image: reviseImage,
    description:
      "Brush up your basics from our app, consistently practice the questions, keep clearing your milestones, revise all the previous year questions and topics from every subject.Use our bookmark features and Customized MCQs tab to customize your revision.",
  },
  {
    title: "Achieve",
    image: achieveImage,
    description:
      "At Cuspyd, we're dedicated to helping you begin your journey towards a successful dental career. Unlock your full potential by taking your preparation to the next level and achieve the results you deserve.",
  },
];

const seo = {
  "/about-us": {
    title: "DocTutorials - NEET PG Course | NEET SS Course | FMGE Course",
    description:
      "DocTutorials provides comprehensive Medical Entrance Exam coaching program with an array of high-quality content including Videos, Qbank and Test Series.",
    keywords:
      "neet pg preparation, ini cet preparation, neet pg online preparation, online neet ss preparation, best neet pg coaching, best neet ss coaching online, best online coaching for neet pg, best offline coaching for neet pg, top neet pg coaching institute, best next coaching online, best medical entrance coaching online, best medical coaching  online, neet pg books, neet pg questions, neet pg online classes, best neet pg videos, best neet ss videos, best fmge videos, best qbank for neet pg, best qbank for neet ss, best qbank for fmge",
  },
  "/": {
    title: "DocTutorials - NExT, NEET PG, FMGE and NEET SS Preparation",
    description:
      "DocTutorials is a comprehensive medical entrance exam preparation hub for NEET PG/NExT, INI CET, NEET SS, INI SS & FMGE. Login For Success & Experience the Best.",
    keywords:
      "NEET PG Coaching, NEET PG Preparation, NEET PG Online Coaching, NEET PG Online Preparation, NExT Coaching, NExT Preparation, NExT Online Coaching, NExT Online Preparation, NEET PG Face to Face Classes, FMGE Coaching, FMGE Preparation, FMGE Online Coaching, FMGE Online Preparation, NEET SS Coaching, NEET SS Preparation, NEET SS Online Coaching, NEET SS Online Preparation",
  },
};

const getSubArrayData = (arr, num = 3) => {
  let length = arr?.length;
  let i = 0;
  let res = [];
  while (i < length) {
    let currArray = arr?.slice(i, i + 2);
    res.push(currArray);
    i = i + 2;
  }
  return res;
};

const getSubArrayData1 = (arr, num = 1) => {
  let length = arr?.length;
  let i = 0;
  let res = [];
  while (i < length) {
    let currArray = arr?.slice(i, i + 1);
    res.push(currArray);
    i = i + 1;
  }
  return res;
};

export const Home = (props) => {
  const path = props.location.pathname;
  const dispatch = useDispatch();
  const CarouselRef = useRef(null);
  // const [homeData, setHomeData] = useState();
  const { authState, homeData, loadingHomeInProgress } = props;
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(
    whyDoctutorial.map((item) => item.name)
  );
  const [currTestimonialsMobile, setCurrentTestimonialsMobile] = useState([]);
  const [selectedJourney, setSelectedJourney] = useState(
    yourJourney.map((item) => item.name)
  );
  const [currJourneysMobile, setcurrJourneysMobile] = useState([]);
  const [liveTestsShown, setLiveTestsShown] = useState(false);
  const [autoplay, setAutoPlay]  = useState(true)

  useEffect(() => {
    let plain = [];
    let plain_formobile = [];

    plain_formobile = getSubArrayData1(whyDoctutorial, 1);

    setCurrentTestimonialsMobile(plain_formobile);

    plain_formobile = getSubArrayData1(yourJourney, 1);

    setcurrJourneysMobile(plain_formobile);
  }, [selected, selectedJourney]);

  useEffect(() => {
    fetchIndividualBlog();
  }, []);

  useEffect(() => {
    if (!loadingHomeInProgress) {
      setTimeout(() => {
        // Wait till dom is updated with home data
        handleNavigation();
      }, 100);
    }
  }, [loadingHomeInProgress, window.location.pathname]);

  useEffect(() => {
    if (props.homeData) {
      setTimeout(() => {
        handleNavigation();
      }, 100);
    }
  }, [props.homeData]);

  const handleNavigation = () => {
    if (window.location.pathname == "/reviews") {
      scrollToElement("success-stories");
    } else if (window.location.pathname == "/contact-us") {
      scrollToElement("get-in-touch");
    }
    // else if (window.location.pathname == '/about') {
    //     scrollToElement('about');
    // }
    else {
      // Navigate other pages
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    // console.log({ element });
    // if (!!element && homeData && homeData.analytics) {
    //   const header = document.getElementById("header-component");
    //   const topOfElement =
    //     element.offsetTop - header ? header.offsetHeight : 100;
    //   window.scroll({ top: topOfElement, behavior: "smooth" });
    // }
    // if (!!element) element.scrollIntoView({ behavior: 'smooth' });
    if (!!element) {
      const { top } = element.getBoundingClientRect();
      const extraScroll = 100;
      window.scrollBy({
        top: top - extraScroll,
        behavior: "smooth",
      });
    }
  };

  const fetchIndividualBlog = async () => {
    try {
      const res = await axiosRequestSilentPublic(
        "POST",
        "/dataInfo",
        "/homePage",
        {}
      );
      if (res.data.gatewayResponse.status.isSuccess) {
        // setHomeData(res?.data?.gatewayResponse?.response)
        dispatch({
          type: LOADING_HOME_SUCCESS,
          res: res.data.gatewayResponse.response,
        });
      } else {
        dispatch({
          type: LOADING_HOME_ERROR,
          res: res.data.gatewayResponse.status.message,
        });
      }
    } catch (error) {
      toast.error("something went wrong ...");
    }
  };

  return (
    <div className="home position-relative">
      {/* <Helmet>
         <title>{(seo[path] || seo['/']).title}</title>
        <meta name="title" content={(seo[path] || seo["/"]).title}></meta>
        <meta
          name="description"
          content={(seo[path] || seo["/"]).description}
        ></meta>
        <meta name="keywords" content={(seo[path] || seo["/"]).keywords}></meta>
        <link rel="canonical" href="https://www.doctutorials.com/" />
      </Helmet>  */}

      <div className="row justify-content-between">
        <div className="col-12 col-md-5 mobile-screen">
          <div className="text-primary font-light text-center text-md-left mt-5">
            <h5>Comprehensive Platform for</h5>
          </div>
          <div className="mt-2 text-center text-md-left">
            <h3>
              <b>BDS | BDS NExT | NEET MDS </b>
            </h3>
            {/* <h5><b>MBBS CURRICULUM | PG RESIDENCY</b></h5> */}
          </div>
          <div className="mt-md-5">
            We provide you everything you need to excel & build the foundation
            for a successful dental career.We are committed to providing
            students with the highest-quality education and support in their
            preparations for BDS, BDS NExT and MDS. <br />
            <br />
            Quality Medical and dental experts, specialists from various fields
            of dentistry have partnered with Cuspyd exclusively to deliver
            standardized clinical education through audio-visual content,
            structured notes, hand-picked MCQs, highly reviewed question bank
            and flashcards.
          </div>
          {/* <div className="mt-4">
            <div>
              <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                Download DocTutorials{" "}
                <h2 className="mx-2">
                  <b>App</b>
                </h2>{" "}
                Here
              </div>
              <div className="ml-md-4 d-none d-md-flex justify-content-center justify-content-md-start">
                <img alt="qrcode" src={QRCode} style={{ height: "200px" }} />
              </div>
              <div className="d-md-none row mt-3">
                <div className="col-5 ms-auto">
                  <a
                    href="https://play.google.com/store/apps/details?id=in.dgist.doctutorials&pli=1"
                    target="_blank"
                  >
                    <img
                      src="app-download-google.webp"
                      className="img-fluid"
                      alt="download app android"
                    />
                  </a>
                </div>
                <div className="col-5 me-auto">
                  <a
                    href="https://apps.apple.com/in/app/doctutorials/id1475350376"
                    target="_blank"
                  >
                    <img
                      src="app-download-ios.webp"
                      className="img-fluid"
                      alt="download app android"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="col-12 col-md-6 mt-5 image-carousel d-flex justify-content-center align-items-center">
          {/* <div><img src={image1} className='img-fluid' alt='operation' /></div> */}
          {homeData &&
            homeData.heroImages &&
            homeData.heroImages.length > 0 && (
              <Carousel
                ref={CarouselRef}
                infiniteLoop = {true}
                autoPlay = {autoplay}
                showArrows={false}
                showThumbs={false}
                key={selected}
                width="100%"
                height="100%"
                dynamicHeight={true}
                // onClickThumb = {()=> console.log("true")}
              >
                {homeData.heroImages.map((item, index) => {
                  if (item.resourceType == "Video" || item.imagePath.includes("mp4")) {
                    return (
                      <>
                        <div className="banner-video-box">
                          {/* <video key={index} autoPlay >
                          <source src={item.imagePath}/>
                        </video> */}
                          <ReactPlayer
                            url={item.imagePath}
                            controls
                            playing={false}
                            // light = {true}
                            muted={true}
                            config={{
                              file: {
                                attributes: { controlsList: "nodownload" },
                              },
                            }}
                            onContextMenu={(e) => e.preventDefault()}
                            width={"100%"}
                            height={"100%"}
                            onPlay = {()=>setAutoPlay(false)}
                          />
                        </div>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <img key={index} src={item.imagePath} alt="student" />
                      </>
                    );
                  }
                })}
              </Carousel>
            )}
          {/* <div
            className="mt-5 demo-video-btn"
            onClick={() => setShowModal(true)}
          >
            <img
              src={playButton}
              className="mx-auto"
              alt="play button"
              height={90}
            />
            <div className="demo-video-btn-text" role="button">
              V2.023
            </div>
          </div> */}
        </div>
      </div>

      <div className="mt-5 mt-md-3 Why_DocTutorials">
        <div className="text-center text-md-left">
          <span className="d-md-none d-flex align-items-center gap-2">
            <h3>Why</h3>
            <h1 className="font-medium">Cuspyd</h1>
          </span>
          <h3 className="d-none d-md-block">Why</h3>
          <h1 className="font-medium d-none d-md-block">Cuspyd</h1>
        </div>
        <div className="d-none d-md-block">
          <div className="row mt-4 ">
            <div className="col-12 col-lg-4 col-md-6 col-sm-12  mb-5">
              <div className="card shadow text-center p-5">
                <h2>Effective & Dynamic</h2>
                <h2>3D videos</h2>
                <div className="image-box box-3">
                  <img src={planImage} alt="plan" />
                </div>
                <div className="howerText bg-light-gray p-5">
                  <h2>Effective & Dynamic 3D videos</h2>
                  <div className="mt-5 text-justify">
                    With our engaging, lively 3D visuals and easy-to-follow
                    videos, you'll be able to quickly and confidently retain the
                    concepts from each topic better & get a headstart on your
                    BDS/BDS NExT preparations. Our expert faculty members
                    present complex medical & dental concepts in a simple and
                    understandable way.
                    {/* Our video lectures are designed to be crisp and concise, so that students can retain the most important content from each topic. Our expert faculty members present complex medical concepts in a simple and understandable way. */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4 col-md-6 col-sm-12  mb-5 ">
              <div className="card shadow text-center p-5 curated-test">
                <h2>Structured Notes </h2>
                <div className="image-box box-3">
                  <img src={mcqCore} alt="Mq core" />
                </div>
                <div className="howerText bg-light-gray p-5">
                  <h2>Structured Notes</h2>
                  <div className="mt-5 text-justify">
                    Maximize your exam preparation with our comprehensive notes
                    covering all the key concepts and previously asked questions
                    you need to know for the exam.Our pointwise notes, having
                    tables, flowcharts, integrated mnemonics and easy-to-draw
                    illustrations, give you an edge on exam day.{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-md-6 col-sm-12  mb-5 ms-auto me-auto">
              <div className="card shadow text-center p-5">
                <h2>Comprehensive </h2>
                <h2>Qbank</h2>
                <div className="image-box box-3">
                  <img src={preparationImage} alt="preparation" />
                </div>
                <div className="howerText bg-light-gray p-5">
                  <h2>Comprehensive </h2>
                  <h2>Qbank</h2>
                  <div className="mt-5 text-justify">
                    Boost your confidence with our extensive collection of
                    previously asked questions from various university exams.
                    Use our collection of previously asked questions to take
                    your preparation to the next level. It is the perfect
                    resource for BDS and BDS NExT exam preparation.
                    {/* Our QBank houses MCQs curated by top doctors. We ensure that each type of question - MCQs, Image Based Questions, and Video Based questions are updated as per the latest exam pattern. Our QBank is a valuable resource for students to practice and test their knowledge. */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4 col-md-6 col-sm-12 mb-5 ms-auto me-auto">
              <div className="card shadow text-center p-5">
                <h2>Multiple Choice Questions</h2>
                <div className="image-box box-3">
                  <img src={quickVideos} alt="quick videos" />
                </div>
                <div className="howerText bg-light-gray p-5">
                  <h2>Multiple Choice Questions </h2>
                  <div className="mt-5 text-justify">
                    Self assess your knowledge by answering MCQs curated
                    exclusively from standard textbooks and previous competitive
                    exams after every topic for your success in NEET MDS.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-md-6 col-sm-12 mb-5">
              <div className="card shadow text-center p-5">
                <h2>Flashcards </h2>
                <div className="image-box box-3">
                  <img src={mcqVideos} alt="MCQ videos" />
                </div>
                <div className="howerText bg-light-gray p-5">
                  <h2>Flashcards</h2>
                  <div className="mt-5 text-justify">
                    Our handpicked flashcards provide a concise and effective
                    way for students to review high yield topics,important
                    values and mnemonics in a short amount of time. This makes
                    it an invaluable resource for busy students before exams.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-md-6 col-sm-12  mb-5">
              <div className="card shadow text-center p-5">
                <h2>Experienced Faculty</h2>
                <div className="image-box box-1 mt-5">
                  <img src={facultyTeam} alt="faculty team" />
                </div>
                <div className="howerText bg-light-gray p-5">
                  <h2>Experienced Faculty</h2>
                  <div className="mt-5 text-justify">
                    Within our platform, you can benefit from the guidance of
                    proficient faculty members, well-versed in their subjects.
                    Their commitment to delivering top-quality classes is
                    paralleled by their devotion to mentoring you, assuring
                    thorough resolution of all your doubts. This approach
                    ensures a holistic learning experience.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-md-none">
          <Carousel ref={CarouselRef} showThumbs={false} key={selected}>
            {!!currTestimonialsMobile &&
              currTestimonialsMobile?.map((item, index) => {
                return (
                  <div key={index} className="row mt-4">
                    {item?.map((i, index) => {
                      return (
                        <div
                          key={index}
                          className="col-11 col-md-4 m-auto mb-5"
                        >
                          <div className="card shadow p-3 bg-light-blue">
                            <h2>{i?.title}</h2>
                            <div className="image-box box-1 mt-5" style={{height: '0%'}}>
                              <img src={i?.image} alt="student" />
                            </div>
                            <div className="howerText bg-light-gray p-5">
                              <h2>{i?.title}</h2>
                              <div className="mt-5 text-justify">
                                {i?.description}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </Carousel>
        </div>
      </div>

      <div
        style={{ paddingTop: "11rem" }}
        id="about"
        className="pt-5 pt-md-0 about"
      >
        <span className="d-md-none d-flex align-items-center gap-2">
          <h3>Your journey with</h3>
          <h1 className="font-medium">Cuspyd</h1>
        </span>
        <h3 className="d-none d-md-block">Your journey with</h3>
        <h1 className="font-medium d-none d-md-block">Cuspyd</h1>
        {/* <div className="d-none d-md-block"> */}
          <div className="d-none d-md-flex justify-content-around align-items-center flex-wrap" style={{alignSelf: 'stretch',boxSizing: 'border-box'}}>
            <div className="col-12 col-md-5  mb-5" style={{height: '100%'}}>
              <div className="card shadow p-3 bg-light-blue">
                <h3>
                  <b>Learn</b>
                </h3>
                <div className="image-box box-1">
                  <img src={learnImage} alt="learn" />
                </div>
                <div className="m-3 mb-5">
                  Get your basics strong from the core fundamentals, learn from
                  our Video lectures, MCQs , Question bank, structured notes and
                  flashcards . Build the foundation to a successful dental
                  career.
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5  mb-5">
              <div className="card shadow p-3 bg-light-gray">
                <h3>
                  <b>Understand</b>
                </h3>
                <div className="image-box box-1">
                  <img src={understandImage} alt="understand" />
                </div>
                <div className="m-3 mb-5">
                  Commit yourself into exploring every aspect of your dental
                  curriculum and understand better. Cuspyd will be your
                  companion from classrooms to dental clinics, anytime,
                  anywhere.
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5  mb-5">
              <div className="card shadow p-3 bg-light-gray">
                <h3>
                  <b>Revise</b>
                </h3>
                <div className="image-box box-1">
                  <img src={reviseImage} alt="plan" />
                </div>
                <div className="m-3">
                  Brush up your basics from our app, consistently practice the
                  questions, keep clearing your milestones, revise all the
                  previous year questions and topics from every subject.Use our
                  bookmark features and Customized MCQs tab to customize your
                  revision.
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5  mb-5">
              <div className="card shadow p-3 bg-light-blue">
                <h3>
                  <b>Achieve</b>
                </h3>
                <div className="image-box box-1">
                  <img src={achieveImage} alt="achieve" />
                </div>
                <div className="m-3 mb-5">
                  At Cuspyd, we're dedicated to helping you begin your journey
                  towards a successful dental career. Unlock your full potential
                  by taking your preparation to the next level and achieve the
                  results you deserve.
                </div>
              </div>
            </div>
          </div>
        {/* </div> */}
        <div className="d-md-none">
          <Carousel ref={CarouselRef} showThumbs={false} key={selected}>
            {!!currJourneysMobile &&
              currJourneysMobile?.map((item, index) => {
                return (
                  <div key={index} className="row mt-4">
                    {item?.map((i, index) => {
                      return (
                        <div
                          key={index}
                          className="col-11 col-md-4 m-auto mb-5"
                        >
                          <div className="card shadow p-3 bg-light-blue">
                            <h2>{i?.title}</h2>
                            <div className="image-box box-1 mt-5">
                              <img src={i?.image} alt="" />
                            </div>
                            <div className="howerText bg-light-gray p-5">
                              <h2>{i?.title}</h2>
                              <div className="mt-5 text-justify">
                                {i?.description}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </Carousel>
        </div>
      </div>

      {!!homeData && homeData.plans && (
        <div className="mt-5 mt-md-5">
          <h1 className="mb-5 font-medium text-center text-md-left">Courses</h1>
          <div className="row mt-5">
            <div className="col-12 col-md-auto">
              <div className="course-name">UG CURRICULUM</div>
            </div>
            <div className="col ms-md-4 mt-3 align-self-center">
              Shine in your classes & feel more prepared for university exams.
              Cuspyd’s UG CURRICULUM course would be a perfect fit for you. Stream 3D
              animated video lectures, practice MCQs and browse through notes of
              all topics at the click of a button.
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 col-md-auto">
              <div className="course-name">BDS NExT</div>
            </div>
            <div className="col ms-md-4 mt-3 align-self-center">
              Thinking about how BDS NExT will affect your undergraduate
              studies? Cuspyd has you covered. With a unique approach to
              clinical application of concepts, using flashcards and in-depth
              notes and MCQs right from first year BDS, we prepare you for
              anything that lies ahead in your dental journey.
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 col-md-auto">
              <div className="course-name">NEET MDS</div>
            </div>
            <div className="col ms-md-4 mt-3 align-self-center">
              Are you still looking to crack one of India's most competitive
              NEET MDS exams? It requires solid preparation and a focused
              mindset. Our upcoming course is designed specifically keeping in
              mind the requirements of the exam and tailor- made for MDS
              aspirants.
            </div>
          </div>
        </div>
      )}

      {/* <div className="mt-5 mt-md-5">
        <h1 className="mb-5 text-center font-medium">
          Study Smarter, Not Harder
        </h1>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="px-5 notes-image">
              <div className="">
                <img src={notesImage} className="img-fluid" alt="notes" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 h-100">
            <div className="mt-3 mt-md-5">
              Buy Notes! Written by Doctors, For Doctors You'd be fortunate if
              you've ever had the benefit of last-minute revision from notes
              prepared by one of your Topper batchmates. What if we told you
              that throughout your preparation, you will receive notes
              specifically created by well versed doctors that would help you
              better review all the topics? Isn't that half the work done? Yes.
              We offer high-quality notes in digital soft copy format on the
              app. Also better, for the bookworm in you, we offer hard-copy
              notes by order only for all NEET PG aspirants
            </div>
            <div
              className="text-center text-md-center ms-auto mt-8"
              style={{ marginTop: "50px" }}
            >
              <Link to={"/plans"}>
                <button className="btn btn-blue large">Learn More</button>
              </Link>
            </div>
          </div>
        </div>
      </div> */}

      {/* {!!homeData && homeData.analytics && <div className='mt-5'>
                <h1 className='font-medium text-center text-md-left'>Analytics</h1>
                <div className='row'>
                    <div className='col-6 col-md-3 mb-3 mb-md-5 p-md-5'>
                        <div className='card shadow px-5 py-4 p-md-5' >
                            <div className='d-flex align-items-end text-blue'>
                                <h1 className='large-font'><b>{homeData.analytics.appDownlaods}</b></h1>
                                <h3><b>L+</b></h3>
                            </div>
                            <h1 className='text-gray'><b>App</b></h1>
                            <div className='text-gray font-12'>Downloads</div>
                        </div>
                    </div>
                    <div className='col-6 col-md-3 mb-3 mb-md-5 p-md-5'>
                        <div className='card shadow px-5 py-4 p-md-5' >
                            <div className='d-flex align-items-end text-blue'>
                                <h1 className='large-font'><b>{homeData.analytics.videoHours}</b></h1>
                                <h3><b>L+</b></h3>
                            </div>
                            <h1 className='text-gray'><b>Hours</b></h1>
                            <div className='text-gray font-12'>Videos Watched</div>
                        </div>
                    </div>
                    <div className='col-6 col-md-3 mb-3 mb-md-5 p-md-5'>
                        <div className='card shadow px-5 py-4 p-md-5' >
                            <div className='d-flex align-items-end text-blue'>
                                <h1 className='large-font'><b>{homeData.analytics.mcqSolved}</b></h1>
                                <h3><b>L+</b></h3>
                            </div>
                            <h1 className='text-gray'><b>MCQ</b></h1>
                            <div className='text-gray font-12'>Solved</div>
                        </div>
                    </div>
                    <div className='col-6 col-md-3 mb-3 mb-md-5 p-md-5'>
                        <div className='card shadow px-5 py-4 p-md-5' >
                            <div className='d-flex align-items-end text-blue'>
                                <h1 className='large-font'><b>{homeData.analytics.testTaken}</b></h1>
                                <h3><b>L+</b></h3>
                            </div>
                            <h1 className='text-gray'><b>Tests</b></h1>
                            <div className='text-gray font-12'>Taken</div>
                        </div>
                    </div>
                </div>
            </div>} */}
      {showModal && (
        <VideoModal isOpen={showModal} closeModal={() => setShowModal(false)} />
      )}
      {/* <div id='reviews' >
                {(!!homeData && homeData.testimonials) && <DocTutorialCommunity testimonials={homeData.testimonials} />}
            </div> */}
      {!!homeData && homeData.testimonials && <Plans plans={homeData.plans} />}
      <div id="contact-us">
        <GetInTouch />
      </div>
      {!!homeData && homeData.quizData && homeData.quizData.length > 0 && (
        <LiveTestsPopUp
          isAuthenticated={authState?.isAuthenticated}
          homeData={homeData}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  authState: state.authReducer,
  homeData: state.homeReducer.homeData,
  loadingHomeInProgress: state.homeReducer.loadingHomeInProgress,
});

const mapDispathToProps = (dispatch) => ({
  loadAuthenticatedCall: () => dispatch(loadAuthenticated()),
});
export default connect(mapStateToProps, mapDispathToProps)(Home);
