import { axiosRequestNA } from './../apis/api-v1';

export const FTECHING_COURSE = "FTECHING_COURSE";
export const FTECHING_COURSE_SUCCESS = "FTECHING_COURSE_SUCCESS";
export const FTECHING_COURSE_ERROR = "FTECHING_COURSE_ERROR";


export const getCourseInfo = (courseBody) => async (dispatch) => {
    dispatch({ type: FTECHING_COURSE });
    try {
        const res = await axiosRequestNA("POST","/dataInfo","/coursePage",courseBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            dispatch({ type: FTECHING_COURSE_SUCCESS, res:res.data.gatewayResponse.response })
        }else{
            dispatch({ type: FTECHING_COURSE_ERROR, errMsg:res.data.gatewayResponse.status.message });
        }
    } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
            dispatch({ type: FTECHING_COURSE_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: FTECHING_COURSE_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}





