import React from 'react';
import { Modal, ModalBody } from "reactstrap"
import crossIcon from './../assets/Group 2910.png'

const PlanModal = (props) => {
    const { isOpen, toggleModal, plan = {} } = props;
  return (
    <Modal isOpen={isOpen} centered size="lg" toggle={toggleModal}>
        <ModalBody>
            <div className="py-5 p-md-5">
                <img src={crossIcon} alt='plan' className="cross-icon pb-2" height={20} role='button' onClick={toggleModal} />
                <h2 className='text-center'>
                    {plan.name} Information
                </h2>
                <div dangerouslySetInnerHTML={{ __html: plan.bigDescription || '' }}></div>
            </div>
        </ModalBody>
    </Modal>
  )
}

export default PlanModal;