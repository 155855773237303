const commonReducer = (state = { notifications: [], isLoading: false, loadingText: null }, action) => {
    switch (action.type) {
        case 'add-notification':
            return { ...state, notifications: state.notifications.concat(action.payload) }
        case 'remove-notification':
            return { ...state, notifications: state.notifications.filter(item => item.time === action.time) }
        case 'loader':
            return { ...state, isLoading: action.isLoading, loadingText: action.isLoading ? action.loadingText : null }
        default:
            return state
    }
}   

export const commonSelector = state => state
export default commonReducer