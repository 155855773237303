import { Button, InputGroup, InputGroupText, Modal, ModalBody } from "reactstrap"
import { useDispatch } from "react-redux"
import { Input } from "components/Input"
import crossIcon from 'assets/Group 2910.png'
import { useHistory } from "react-router"
import { useEffect, useState } from "react"
import { Form, Field } from 'react-final-form'
import { V, P } from 'utils'
import { Link, withRouter } from "react-router-dom"
import { signin, getGEOLocation, sendOTP, verifyOTPLogin, forgotPassword, resetPassword } from './../store/authentication/action';
import country from './../assets/json/country.json';

const Login1 = () => {
    const [isOpen, setOpen] = useState(false)
    const [viaMobile, setViaMobile] = useState(false)
    const [forgotOpen, setForgotOpen] = useState(false)
    const [isloadingotp, setisloadingotp] = useState(1)
    const [forgotPasswordStep, setForgotPasswordStep] = useState(1);
    const [step, setStep] = useState(1)
    const [state, setState] = useState({ code: '91', email: "", password: "", otpToken: "" })
    const [showPass, setShowPass] = useState(false)
    const [error, setError] = useState(true)

    const [fgpState, setFgpState] = useState({
        studentId: 0,
        otpToken: "",
        newPassword: "",
        otp: "",
        mobileNo: "",
        countryCode: "91"
    })

    const inputfocus = (elmnt) => {
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {

                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            const next = elmnt.target.tabIndex;
            if (next < 6) {
                elmnt.target.form.elements[next].focus()
            }
        }

    }

    const dispatch = useDispatch();

    const history = useHistory();
    const seCountryCode = () => {
        getGEOLocation().then((res) => {
            if (!!res?.data?.gatewayResponse?.Response?.country) {
                const countryCode = res?.data?.gatewayResponse?.Response?.country;
                setState({
                    ...state,
                    code: country[countryCode][1]
                })
            }
        })
    }

    useEffect(() => {
        // seCountryCode();
        setOpen(history.location.search.includes('?login=true'));
        setForgotOpen(history.location.search.includes('?forgot=true'));
        if (history.location.search.includes('?forgot=true')) {
            setForgotPasswordStep(1);
        }
        return () => {
            setStep(1);
        }
    }, [history.location.search]);


    useEffect(() => {
        seCountryCode();
    }, []);


    const closeModal = () => {
        setOpen(false)
        setForgotOpen(false)
        history.push({ search: '' })
    }

    const sendOTPAgain = async () => {
        const gatewayResponse = await sendOTP({
            countryCode: state.code,
            mobileNo: state.mobile
        })
        setState({
            ...state,
            studentId: gatewayResponse.response.studentId,
            otpToken: gatewayResponse.response.otpToken
        })
        setisloadingotp(1);
    }

    const handleSubmit = async values => {
        const redirectPath = history.location.search.includes('sameuri=true') ? history.location.pathname : history.location.search.includes('leaderboard=true') ? '/dashboard/leaderboard' : history.location.search.includes('plans=true') ? "/plans/ug-curriculum" : '/dashboard'
        if (viaMobile) {
            if (step == 1) {
                const gatewayResponse = await sendOTP({
                    countryCode: values.code,
                    mobileNo: values.mobile
                })
                if (gatewayResponse.status.isSuccess) {
                    setStep(2);
                    setState({
                        ...state,
                        code: values.code,
                        mobile: values.mobile,
                        studentId: gatewayResponse.response.studentId,
                        otpToken: gatewayResponse.response.otpToken
                    });
                }
            } else {
                dispatch(verifyOTPLogin({
                    otp: new Array(6).fill(0).map((_, i) => values[`otp_${i}`]).join(""),
                    otpToken: state.otpToken,
                    studentId: state.studentId
                }, () => history.push(redirectPath)))
            }
        } else {
            dispatch(signin(values, () => history.push(redirectPath)));
            const islogin = localStorage.getItem('localStoragedata');
            if (islogin != null) {
                history.push(redirectPath);
            }
        }

    }

    useEffect(() => {
        const interval = setInterval(() => {
            setisloadingotp(0)
        }, 30000);

        return () => clearInterval(interval);
    }, [isloadingotp]);


    const resendOTPForForgotPassword = async () => {
        if (isloadingotp) return
        try {
            setisloadingotp(true)
            const res = await forgotPassword(fgpState);
            setisloadingotp(false)
            setFgpState({ ...fgpState, ...res })
        } catch (error) {
            setisloadingotp(false)
            console.log(error);
        }
    }

    const handleSubmitForgotPassword = async (value) => {
        if (forgotPasswordStep == 1) {
            const res = await forgotPassword(value);
            if (!!res) {
                const { studentId, otpToken } = res;
                setFgpState({
                    ...fgpState,
                    studentId,
                    otpToken,
                    mobileNo: value.mobileNo,
                    countryCode: value.countryCode
                });
                setForgotPasswordStep(2);
            }
        } else {
            console.log(value);
            const body = {
                studentId: fgpState.studentId,
                otpToken: fgpState.otpToken,
                newPassword: value.newPassword,
                otp: new Array(6).fill(0).map((_, i) => value[`otp_${i}`]).join(""),
            }
            const res = await resetPassword(body);
            if (res) {
                setForgotOpen(false);
                history.push("/?login=true");
            }
        }
    }
    const validators = values => ({
        mobile: V.compose(V.required, values.code == "91" ? V.mobile : V.mobile_state)(values.mobile) || undefined,
        code: V.required(values.code) || undefined
    })
    const fgpValidators = values => ({
        mobileNo: V.compose(V.required, values.countryCode == "91" ? V.mobile : V.mobile_state)(values.mobileNo) || undefined,
        countryCode: V.required(values.countryCode) || undefined
    })

    return (<>
        <Modal isOpen={isOpen} centered size="lg" toggle={closeModal}>
            <ModalBody>
                <div className="py-3 px-3 p-md-5">
                    <img src={crossIcon} alt='cross icon' className="cross-icon" height={20} role='button' onClick={closeModal} />
                    <div className="text-center">
                        <h2><b>Login to your account</b></h2>
                    </div>
                    {step === 1 && (<div className="mt-5 mb-3 text-center">
                        <Button color={viaMobile ? "primary" : "light"} onClick={() => setViaMobile(true)}>Mobile</Button>
                        <Button color={!viaMobile ? "primary" : "light"} onClick={() => setViaMobile(false)} className="ms-5">E-mail</Button>
                    </div>)}

                    {viaMobile && step === 1 ? (<Form onSubmit={handleSubmit} validate={validators} initialValues={state} render={({ handleSubmit, ...args }) => {
                        return (<>
                            <form onSubmit={handleSubmit} className='mt-5 row'>
                                <Field name='code'>
                                    {({ input, meta }) => {
                                        return (
                                            <>
                                                {setError(meta.error ? true : false)}
                                                <Input {...input} {...meta} type='select' className="col-5 col-md-3 ms-auto">
                                                    <option value={"91"}>India(+91)</option>
                                                    {Object.keys(country).sort((a, b) => a - b).map((item) => (
                                                        <option value={country[item][1]}>
                                                            {country[item][0]}(+{country[item][1]})
                                                        </option>))}
                                                </Input>
                                            </>
                                        )
                                    }}
                                </Field>
                                <Field name='mobile'>
                                    {({ input, meta }) => (
                                        <>
                                            {setError(meta.error ? true : false)}
                                            <Input {...input} {...meta} inputmode="numeric" type="text" className="col col-md-5 me-auto"
                                                placeholder="Enter phone number" maxLength={10} />
                                        </>
                                    )}
                                </Field>

                                <div className="mt-5 text-center">
                                    <Button color="light" className={`${error ? '' : 'highlight'}`} type="submit" size="lg">Get OTP</Button>
                                </div>
                                <h5 className="mt-5 text-center">
                                    <b>You don't have an account? <Link to='/?signup=true' toggle={closeModal} className="text-orange">Signup</Link></b>
                                </h5>
                            </form>
                        </>)
                    }} />) : viaMobile && step === 2 ? (<Form onSubmit={handleSubmit} initialValues={state} render={({ handleSubmit }) => (<>
                        <form onSubmit={handleSubmit} className='mt-5 row justify-content-center'>
                            <div className="col-12 text-center mb-5">
                                Please enter your OTP, sent to {state.code} {state.mobile} <b className="text-underline ms-2" role='button' onClick={() => setStep(1)}>Change phone number</b>
                            </div>
                            {new Array(4).fill(0).map((_, i) => (
                                <Field name={`otp_${i}`} parse={P.number} >
                                    {({ input, meta }) => (
                                        <Input {...input} tabIndex={i + 1} onKeyUp={e => inputfocus(e)} {...meta} type='text' className='col-auto' inputClassName={`otp-input text-center ${input.value ? 'border-orange' : 'border-black'}`} maxLength={1} />
                                    )}
                                </Field>
                            ))}
                            <div className="mt-5 text-center">
                                <Button color="light" type="submit" size="lg">SUBMIT</Button>
                            </div>
                            <h5 className="mt-5 text-center">
                                {isloadingotp == 0 ? <a className="text-primary text-underline" onClick={sendOTPAgain}>Re-send OTP</a> :
                                    <a className="text-primary text-mute text-underline" >Re-send OTP </a>}
                            </h5>
                        </form>
                    </>)} />) : !viaMobile ? (<Form onSubmit={handleSubmit} initialValues={state} validateOnBlur={false}  render={({ handleSubmit }) => (<>
                        <form onSubmit={handleSubmit} className='mt-5 row justify-content-center'>
                            <Field name='email' validate={V.compose(V.required, V.email)} isInputFocused={false}>
                                {({ input, meta }) => (
                                    <Input {...input} {...meta} type="text" className="col-12 col-md-7 mx-auto" placeholder="Email Id" maxLength={50} />
                                )}
                            </Field>
                            <Field name='password' validate={V.compose(V.required)} isInputFocused={false}>
                                {({ input, meta }) => (
                                    <div className="col-12 col-md-7 mt-3">
                                        <InputGroup>
                                            {setError(meta.error ? true : false)}
                                            <Input {...input} {...meta} hideError className="form-control p-0 shadow-none" type={showPass ? "text" : "password"} placeholder="Password" />
                                            <InputGroupText className="text-primary bg-transparent" role='button' onClick={() => setShowPass(!showPass)}>{showPass ? 'HIDE' : 'SHOW'}</InputGroupText>
                                        </InputGroup>
                                        {meta.error && (meta.dirty || meta.submitFailed) && meta.touched &&<div className="text-danger">{meta.error}</div>}
                                    </div>
                                )}
                            </Field>
                            <div className="mt-2 col-12 col-md-7 d-flex justify-content-end">
                                <Link to='/?forgot=true' className="text-dark">Forgot Password?</Link>
                            </div>
                            <div className="mt-5 text-center">
                                <Button color="light" className={`${error ? '' : 'highlight'}`} type="submit" size="lg">Login</Button>
                            </div>
                            <h5 className="mt-5 text-center">
                                <b>You don't have an account? <Link to='/?signup=true' toggle={closeModal} className="text-orange">Signup </Link></b>
                            </h5>
                        </form>
                    </>)} />) : null}
                </div>
            </ModalBody>
        </Modal>

        <Modal isOpen={forgotOpen} centered size="lg" toggle={closeModal}>
            <ModalBody>
                <div className="py-3 px-3 p-md-5">
                    <img src={crossIcon} alt='cross icon' className="cross-icon" height={20} role='button' onClick={closeModal} />
                    <div className="text-center">
                        <h2><b>Forgot Password</b></h2>
                    </div>

                    {forgotPasswordStep == 1 && <Form onSubmit={handleSubmitForgotPassword} validate={fgpValidators} initialValues={fgpState} render={({ handleSubmit }) => (<>
                        <form onSubmit={handleSubmit} className='mt-5 row justify-content-center'>
                            <div className="col-12 col-md-6 mb-4">
                                <div className="row">
                                    <Field name='countryCode' validate={V.compose(V.required)}>
                                        {({ input, meta }) => (
                                            <>
                                                {setError(meta.error ? true : false)}
                                                <Input {...input} {...meta} type='select' className="d-inline-flex col-3 ms-auto pr-0">
                                                    <option value={"91"}>India(+91)</option>
                                                    {Object.keys(country).sort((a, b) => a - b).map((item) => (
                                                        <option value={country[item][1]}>
                                                            +{country[item][1]}
                                                        </option>))
                                                    }
                                                </Input>
                                            </>
                                        )}
                                    </Field>
                                    <Field name='mobileNo' validate={V.compose(V.required, V.mobile_state)}>
                                        {({ input, meta }) => (
                                            <>
                                                {setError(meta.error ? true : false)}
                                                <Input {...input} {...meta} inputmode="numeric" onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} className="col me-auto" placeholder="9999999999" maxLength={10} />
                                            </>
                                        )}
                                    </Field>
                                    
                                </div>
                            </div>
                            <div className="mt-3 text-center">
                                <Button color="light" type="submit" className={`${error ? '' : 'highlight'}`} size="lg">Send OTP</Button>
                            </div>
                        </form>
                    </>)} />}
                    {forgotPasswordStep == 2 && <Form onSubmit={handleSubmitForgotPassword} render={({ handleSubmit }) => (<>
                        <form onSubmit={handleSubmit} className='mt-5'>
                            <div className="col-12 text-center mb-5">
                                Please enter your OTP, sent to {fgpState.countryCode} {fgpState.mobileNo} <b className="text-underline ms-2" role='button' onClick={() => setForgotPasswordStep(1)}>Change phone number</b>
                            </div>
                            <div className="d-flex justify-content-center">
                                {new Array(4).fill(0).map((_, i) => (
                                    <Field name={`otp_${i}`} parse={P.number} >
                                        {({ input, meta }) => (
                                            <Input {...input} tabIndex={i + 1} onKeyUp={e => inputfocus(e)} {...meta} type='text' className='col-auto' inputClassName={`otp-input text-center ${input.value ? 'border-orange' : 'border-black'}`} maxLength={1} />
                                        )}
                                    </Field>
                                ))}
                            </div>
                            <div className="d-flex justify-content-center">
                                <Field name='newPassword' validate={V.compose(V.required, V.password)}>
                                    {({ input, meta }) => (
                                        <Input {...input} {...meta} type="password" className={"form-control border-0"} placeholder="Enter New Password" />
                                    )}
                                </Field>
                            </div>
                            <div className="mt-5 text-center">
                                <Button color="light" type="submit" size="lg">SUBMIT</Button>
                            </div>
                            <h5 className="mt-5 text-center">
                                <a className="text-primary text-underline" onClick={resendOTPForForgotPassword}>Re-send OTP </a>
                            </h5>
                        </form>
                    </>)} />}
                </div>
            </ModalBody>
        </Modal>
    </>)
}

export const Login = withRouter(Login1);