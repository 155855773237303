export const notify = (type, message) => dispatch => {
    let time = new Date().valueOf()
    dispatch({
        type: 'add-notification',
        payload: {
            type,
            message,
            time
        }
    })
    setTimeout(() => {
        dispatch({ type: 'remove-notification', time })
    }, 3000)
}
export const removeNotification = (time) => dispatch => {
    dispatch({ type: 'remove-notification', time })
}

export const loader = (isLoading, loadingText) => dispatch => {
    dispatch({ type: 'loader', isLoading, loadingText })
}