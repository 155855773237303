import React,{useState} from 'react';
import { Button, Label, Modal, ModalBody, Input as ReactInput } from "reactstrap"
import crossIcon from './../assets/Group 2910.png'

const TCModal = (props) => {
    const {isOpen,setTermsOfServices} = props;
    const handleClick = ()=>{
        const checkBox = document.getElementById("accept-checkbox");
        if(!checkBox.checked){
            checkBox.click();
        }
        setTermsOfServices(false);
        
    }
  return (
    <Modal isOpen={isOpen} centered size="lg" toggle={()=>setTermsOfServices(false)}>
    <ModalBody>
    <div className="py-5 p-md-5">
        <img src={crossIcon} alt='cross icon' className="cross-icon" height={20} role='button' onClick={()=>setTermsOfServices(false)} />
        <div className="text-center">
                <h2>Terms & Conditions</h2>
            </div>
        <p className="max-h-200">
            It is a long established fact that a reader will be distracted by the readable 
            content of a page when looking at its layout. The point of using Lorem Ipsum is that 
            it has a more-or-less normal distribution of letters, as opposed to using 'Content here, 
            content here', making it look like readable English. Many desktop publishing packages and 
            web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' 

             will uncover many web sites still in their infancy. Various versions have evolved over the years,
             sometimes by accident, sometimes on purpose (injected humour and the like).
             It is a long established fact that a reader will be distracted by the readable 
            content of a page when looking at its layout. The point of using Lorem Ipsum is that 
            it has a more-or-less normal distribution of letters, as opposed to using 'Content here, 
            content here', making it look like readable English. Many desktop publishing packages and 
            web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' 
            will uncover many web sites still in their infancy. Various versions have evolved over the years,
             sometimes by accident, sometimes on purpose (injected humour and the like).
        </p>
        <div className='d-flex justify-content-center align-items-center'>
            {/* <div> */}
                <Button className='btn btn-primary' onClick={handleClick}>AGREE</Button>
            {/* </div> */}
        </div>
    </div>
    </ModalBody>
</Modal>
  )
}

export default TCModal;