import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import  AppRouter  from './components/router';
import  store  from './store'
import './styles/index.scss'
import Notification from 'components/notification';
import LoadingScreen from 'components/loading-screen';
import 'react-toastify/dist/ReactToastify.css';
import CookiesTab from 'components/CookiesTab'


function App() {
  window.onbeforeunload = function() {
    sessionStorage.removeItem("homepageLoaded"); // To show popup upon reload
  }
  return (
    <Provider store={store}>
        <BrowserRouter>
            <AppRouter />
            <Notification />
            <LoadingScreen />
            <CookiesTab />
        </BrowserRouter>
    </Provider>
  );
}

export default App;
