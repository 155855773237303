import { FTECHING_COURSE, FTECHING_COURSE_SUCCESS, FTECHING_COURSE_ERROR } from './action';

export const initialState = {
    fetchingData: false,
    courseInfo: null,
    errMsg: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FTECHING_COURSE: {
            return {
                ...state,
                fetchingData: true,
            }
        }
        case FTECHING_COURSE_SUCCESS: {
            return {
                ...state,
                fetchingData: false,
                courseInfo: action.res,
                errMsg: null
            }
        }
        case FTECHING_COURSE_ERROR: {
            return {
                ...state,
                fetchingData: false,
                courseInfo: null,
                errMsg: action.errMsg
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}
export default reducer;