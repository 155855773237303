export const courses = [
    {
        "name": "UG-Curriculum",
        "displayName": "UG Curriculum",
        "liveTestText": "INI SS test live now on Leaderboard - Take Test",
        "id": 1
    },
    {
        "name": "NEET-MDS",
        "displayName": "NEET MDS",
        "liveTestText": "DM and MCh Tests live now on Leaderboard - Take Test.",
        // "categories": ['MCh', 'DM'],
        "id": 2
    },
    {
        "name": "BDS-NExT",
        "displayName": "BDS NExT",
        "liveTestText": "All India Test - Take Test",
        "id": 3
    }
]