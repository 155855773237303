import { axiosRequestNA } from './../apis/api-v1';
import cookies from 'react-cookies'



export const LOADING_HOME = "LOADING_HOME";
export const LOADING_HOME_SUCCESS = "LOADING_HOME_SUCCESS";
export const LOADING_HOME_ERROR = "LOADING_HOME_ERROR";


export const loadGeneral = () => async (dispatch, getState) => {
    dispatch({ type: LOADING_HOME });
    try {
        const state = getState().authReducer
        const res = await axiosRequestNA("POST","/dataInfo","/homePage",{ 
            studentId: state?.isAuthenticated ? state.profileInfo?.studentId : undefined, 
            courseId: state?.isAuthenticated ? state.profileInfo?.courseId : undefined, 
        },{},dispatch, false);
        if(res.data.gatewayResponse.status.isSuccess){
            dispatch({ type: LOADING_HOME_SUCCESS, res:res.data.gatewayResponse.response })
        }else{
            dispatch({ type: LOADING_HOME_ERROR, res:res.data.gatewayResponse.status.message });
        }
    } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
            dispatch({ type: LOADING_HOME_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: LOADING_HOME_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

export const loadAuthenticated = (signInBody) => async (dispatch) => {
    dispatch({ type: LOADING_HOME });
    try {
        const res = await axiosRequestNA("POST","/student","/login",signInBody,{},dispatch);
        if(res.data.gatewayResponse.status.isSuccess){
            dispatch({ type: LOADING_HOME_SUCCESS, res:res.data.gatewayResponse.response })
            const sessionToken = res.data.gatewayResponse.response.sessionToken;
            const refreshToken = res.data.gatewayResponse.response.refreshToken;
            cookies.save('sessionToken',sessionToken);
            cookies.save('refreshToken',refreshToken);
        }else{
            dispatch({ type: LOADING_HOME_ERROR, res:res.data.gatewayResponse.status.message });
        }
    } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
            dispatch({ type: LOADING_HOME_ERROR, errMsg: error?.response?.data });
            return;
        }
        dispatch({ type: LOADING_HOME_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}


