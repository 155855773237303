import { useEffect, useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { useParams, withRouter } from 'react-router';
import { Dropdown, DropdownItem, DropdownMenu } from 'reactstrap'
import { connect } from 'react-redux'
import logo from 'assets/Cuspyd-Logo.png'
import instagram from 'assets/instagram.png'
import facebook from 'assets/facebook.png'
import youtube from 'assets/Mask Group 7.png'
import telegram from 'assets/telegram (1).png'
import twitter from 'assets/Logo_of_Twitter.png'
import { logout, tryToAuthenticate, updateAuthUserInfo } from 'store/authentication/action';
import ChangeCourseModal from './ChangeCourseModal';
import manicon from './../assets/Group 9547.png'
import { courses } from 'utils/courses';


export const Header = (props) => {
    const history = useHistory()
    const data = useParams()

    const [isDropdownOpen, setDropdownOpen] = useState(false)
    const [isSidebarOpen, setSidebarOpen] = useState(false)
    const [courseModal, setCourseModal] = useState(false);
    const [isLogout, setIsLogout] = useState(false);
    const [islogins, setislogins] = useState(0);
    const { Logout, authState, tryToAuthenticate } = props;

    // console.log("Logout",authState)

    const LogoutUser = () => {
        const confirmBox = window.confirm(
            "Are you sure do you want to logout?"
        )
        if (confirmBox === true) {
            setIsLogout(true)
            history.replace("/")
            Logout();
        }
    }

    useEffect(() => {
        setSidebarOpen(false)
        setDropdownOpen(false)
    }, [history.location.pathname, history.location.search]);

    const refreshPage = () => {
        history.push('/dashboard');
    }
    const handleLinkClick = (id) => {
        const element = document.getElementById(id);
        if (!!element) element.scrollIntoView({ behavior: 'smooth' });
    }

    var isDashboardOpen = history.location.pathname.startsWith('/dashboard')
    const anatomyExam = history.location.pathname.startsWith('/dashboard/anatomy-exam')
    const testSeries = history.location.pathname.startsWith('/dashboard/test-series-questions')
    const leaderboard = history.location.pathname.startsWith('/dashboard/leaderboard-questions')
    useEffect(() => {
        const localStoragedata = localStorage.getItem('localStoragedata');
        if (authState.isAuthenticated || localStoragedata) {
            setislogins(1)
        } else {
            setislogins(0)
            setIsLogout(false)
        }
        if (!authState.isAuthenticated && localStoragedata) tryToAuthenticate()
        const courseId = localStorage.getItem('CourseKey');
        if (courseId || courseId == null) {
            localStorage.setItem('CourseKey', props?.authState?.profileInfo?.courseId);
            localStorage.removeItem('testseriestab')
        }
    }, [authState.isAuthenticated, Logout]);

    const toggleDropdown = () => setDropdownOpen(!isDropdownOpen)
    const toggleSidebar = () => setSidebarOpen(!isSidebarOpen)

    //    console.log('isDashboardOpen header' , isDashboardOpen)
    //    if(isDashboardOpen != true || isDashboardOpen == 'undefined' ){

    const pathname = props.location.pathname;
    const check_pathname = pathname.split('/');
    if (check_pathname[1] == 'dashboard') {
        isDashboardOpen = true;
    } else {
        isDashboardOpen = false;
    }

    if (isDashboardOpen) return (
        <div className={`${anatomyExam || testSeries || leaderboard ? 'container-fluid shadow pt-2 px-md-5' : 'container-fluid shadow pt-2 px-md-5 header-component'}`}>
            <div className="d-flex justify-content-between align-items-center flex-wrap" style={{flexDirection: 'row',padding: '10px 0px'}}>
                <div className=" text-center text-md-left">
                    <Link to='/'>
                        <img src={logo} height="35" width="130" className='pointer' alt='logo' />
                    </Link>

                </div>
                <div className='d-flex my-auto middle'>
                    <div className='d-flex position-relative hover-active'>
                        <Link to={history.location.pathname} onClick={() => setCourseModal(true)}
                            className='btn btn-blue change-course-button mobile-view-course-btn'>Change Course</Link>
                    </div>
                    <div className='ms-3 mt-2 d-md-none'>
                        <Link to={"/dashboard/profile"}>
                            <img alt='user profile' src={props?.authState?.profileInfo?.image || manicon} className='mr-2 mobile-profile-avatar' />
                        </Link>
                    </div>
                    <div className='ms-3 align-items-center d-md-none mt-2'>
                        <span className='text-primary pointer' onClick={() => { LogoutUser() }}><span className='fa fa-sign-out fa-2x'></span></span>
                    </div>
                </div>
                <div className=' d-none d-md-flex align-items-center'>
                    <div className='me-4'>
                        <img  className='pointer' onClick={() => window.open("https://www.instagram.com/cuspyd", "_blank")} src={instagram} height='25' alt='instagram' />
                    </div>
                    <div className='me-4'>
                        <img className='pointer' onClick={() => window.open("https://www.facebook.com/cuspyd", "_blank")} src={facebook} height='32' alt='facebook' />
                    </div>
                    <div className='me-4'>
                        <img className='pointer' onClick={() => window.open("https://www.youtube.com/@cuspyd", "_blank")} src={youtube} height='25' alt='youtube' />
                    </div>
                    <div className='me-4'>
                        <img className='pointer' onClick={() => window.open("https://twitter.com/cuspyd_dental", "_blank")} src={twitter} height='25' alt='telegram' />
                    </div>
                    <div className='me-5'>
                        <img className='pointer' onClick={() => window.open("https://t.me/+AGmkHLwdO3A5ZGRl", "_blank")} src={telegram} height='25' alt='telegram' />
                    </div>
                    <div className='me-5'>
                        <p className='text-primary pointer mb-0' onClick={() => { LogoutUser() }} ><b>Logout </b></p>
                    </div>
                    <div className='ms-5 user-name'>
                        <div className='mx-3'>
                            <Link to={"/dashboard/profile"}>
                                <img alt='user profile' src={props?.authState?.profileInfo?.image || manicon} width="50" height={"50"} className='mr-2' />
                                {props?.authState?.profileInfo?.name}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {courseModal && <ChangeCourseModal isOpen={courseModal} updateAuthUserInfo={props.updateAuthUserInfo} refreshPage={refreshPage} closeModal={() => setCourseModal(false)} courseid={props?.authState?.profileInfo?.courseId} />}
        </div>
    )
    return (
        <div id="header-component" className='container-fluid pt-2 header-component shadow' style={{paddingBottom: '20px'}}>
            <div className='container'>
            <div className={`sidebar-nav-container p-3 ${isSidebarOpen ? "active" : ""} d-md-none`}>
                <span className={`fa fa-${isSidebarOpen ? 'times' : 'bars'} fa-2x mt-4 ms-3`} role='button'
                    onClick={toggleSidebar}></span>

                <div className={`font-18 ${isSidebarOpen ? 'd-block' : 'd-none'}`}>
                    <div className='mt-5'>
                        <Link to='/about-us' className='nav-link'>About</Link>
                    </div>
                    {/* courses dropdown */}
                    {/* <div className='mt-3'>
                        <div class="dropdown">
                            <a className={`dropdown-toggle nav-link ${pathname.startsWith('/course') ? 'active' : ''}`} onClick={toggleDropdown} data-bs-toggle="dropdown" aria-expanded="false">Courses </a>

                            <ul class="dropdown-menu dropdown-menu-dark">
                                {courses.map((item, i) => (
                                    <div key={i}>
                                        <li>  <NavLink to={`/course/${item.name.toLowerCase()}`} activeClassName="active-drop" className='nav-link font-12 font-medium'>{item.displayName}</NavLink></li>
                                    </div>
                                ))}
                            </ul>
                        </div>
                    </div> */}
                    <div className='mt-3'>
                        <Link to='/blogs' className='nav-link'>Blogs</Link>
                    </div>
                    <div className='mt-3'>
                        <Link to='/plans/ug-curriculum' className='nav-link'>Plans</Link>
                    </div>
                    <div className='mt-3'>
                        <Link to='/contact-us' className='nav-link'>Contact Us</Link>
                    </div>

                    <div className='my-5 text-center'>
                        {islogins !== 1 || isLogout ? <Link to='/?login=true' className='btn btn-blue'>Login/signup</Link> : (<>
                            <Link to='/dashboard' className='btn btn-blue'>Dashboard</Link>
                            <p className='text-primary pointer mb-0 mt-3' onClick={Logout}><b>Logout </b></p>
                        </>)
                        }
                    </div>
                </div>
            </div>
            <div className="row align-items-center pt-3">
                <div className="col text-center text-md-left">
                    <Link to='/'>
                        <img src={logo} height='35' className='pointer' alt='logo' />
                    </Link>
                </div>
                <div className='col-auto d-none d-md-flex align-items-center' >
                    {/* <div className='me-4'>
                        <img className='pointer' onClick={() => window.open("https://www.instagram.com/doctutorials_", "_blank")} src={instagram} height='25' alt='instagram' />
                    </div>
                    <div className='me-4'>
                        <img className='pointer' onClick={() => window.open("https://www.facebook.com/doctutorials", "_blank")} src={facebook} height='32' alt='facebook' />
                    </div>
                    <div className='me-4'>
                        <img className='pointer' onClick={() => window.open("https://www.youtube.com/doctutorials", "_blank")} src={youtube} height='25' alt='youtube' />
                    </div>
                    <div className='me-4'>
                        <img className='pointer' onClick={() => window.open("https://t.me/DocTutorials", "_blank")} src={telegram} height='25' alt='telegram' />
                    </div> */}
                    <div className='col-auto ms-auto'>
                    <Link to={'/about-us'} className={`nav-link ${pathname.startsWith('/about') ? 'active' : ''}`}>About</Link>
                    {/* <span  onClick={()=>handleLinkClick("about")} className='nav-link pointer'>About s</span> */}
                </div>
                {/* courses dropdown */}
                {/* <div className='col-auto ms-4'>
                    <div class="dropdown">
                        <a className={`dropdown-toggle nav-link`} onClick={toggleDropdown} data-bs-toggle="dropdown" aria-expanded="false"><span className={`${pathname.startsWith('/course') ? 'active-drop' : ''}`}>Courses</span> </a>
                        <ul class="dropdown-menu dropdown-menu-dark">
                            {courses.map((item, i) => (
                                <div key={i}>
                                    <li onClick={toggleDropdown}>  <NavLink to={`/course/${item.name.toLowerCase()}`} activeClassName="active-drop" className='nav-link font-12 font-medium'>{item.displayName}</NavLink></li>
                                </div>
                            ))}
                        </ul>
                    </div>
                </div> */}
                <div className='col-auto ms-4'>
                    {/* <span  onClick={()=>handleLinkClick("review")} className='nav-link pointer'>Reviews</span> */}
                    <Link to='/blogs' className={`nav-link ${pathname.startsWith('/blogs') ? 'active' : ''}`}>Blogs</Link>

                </div>
                <div className='col-auto ms-4'>
                    <Link to='/plans/ug-curriculum' className={`nav-link pointer ${pathname.startsWith('/plans') ? 'active' : ''}`}>Plans</Link>

                </div>
                <div className='col-auto ms-4'>
                    <Link to='/contact-us' className={`nav-link ${pathname.startsWith('/contact-us') ? 'active' : ''}`}>Contact Us</Link>

                    {/* <span  onClick={()=>handleLinkClick("contact-us")} className='nav-link pointer'>Reach Us</span> */}
                </div>


                    {islogins !== 1 || isLogout ? <div className='me-4'>
                        <Link to='/?login=true' className='btn btn-blue'>Login/signup</Link></div> :
                        <div className='me-4'>
                            <Link to='/dashboard' className='btn btn-blue'>Dashboard</Link>
                            <button className='btn btn-blue ms-3' onClick={() => { LogoutUser() }} >Logout </button>
                        </div>
                    }
                </div>
            </div>
            {/* <div className='row d-none d-md-flex'> */}
                {/* <div className='col-auto ms-auto'>
                    <Link to={'/about-us'} className={`nav-link ${pathname.startsWith('/about') ? 'active' : ''}`}>About</Link> */}
                    {/* <span  onClick={()=>handleLinkClick("about")} className='nav-link pointer'>About s</span> */}
                {/* </div> */}
                {/* <div className='col-auto ms-4'>
                    <div class="dropdown">
                        <a className={`dropdown-toggle nav-link`} onClick={toggleDropdown} data-bs-toggle="dropdown" aria-expanded="false"><span className={`${pathname.startsWith('/course') ? 'active-drop' : ''}`}>Courses</span> </a>
                        <ul class="dropdown-menu dropdown-menu-dark">
                            {courses.map((item, i) => (
                                <div key={i}>
                                    <li onClick={toggleDropdown}>  <NavLink to={`/course/${item.name.toLowerCase()}`} activeClassName="active-drop" className='nav-link font-12 font-medium'>{item.displayName}</NavLink></li>
                                </div>
                            ))}
                        </ul>
                    </div>
                </div> */}
                {/* <div className='col-auto ms-4'> */}
                    {/* <span  onClick={()=>handleLinkClick("review")} className='nav-link pointer'>Reviews</span> */}
                    {/* <Link to='/blogs' className={`nav-link ${pathname.startsWith('/blogs') ? 'active' : ''}`}>Blogs</Link>

                </div> */}
                {/* <div className='col-auto ms-4'>
                    <Link to='/plans/neet-pg' className={`nav-link pointer ${pathname.startsWith('/plans') ? 'active' : ''}`}>Plans</Link>

                </div> */}
                {/* <div className='col-auto ms-4'>
                    <Link to='/contact-us' className={`nav-link ${pathname.startsWith('/contact-us') ? 'active' : ''}`}>Contact Us</Link> */}

                    {/* <span  onClick={()=>handleLinkClick("contact-us")} className='nav-link pointer'>Reach Us</span> */}
                {/* </div> */}

            {/* </div> */}
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    authState: state.authReducer
})
const mapDispatchToProps = (dispatch) => ({
    Logout: () => dispatch(logout()),
    tryToAuthenticate: () => dispatch(tryToAuthenticate()),
    updateAuthUserInfo: (data) => dispatch(updateAuthUserInfo(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
