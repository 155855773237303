import { AUTHENTICATING_USER, AUTHENTICATING_USER_SUCCESS, AUTHENTICATING_USER_ERROR ,LOGOUT_USER, UPDATE_USER} from './action';

export const initialState = {
    authenticationInProgress: false,
    profileInfo: null,
    isAuthenticated: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTHENTICATING_USER: {
            return {
                ...state,
                authenticationInProgress: true,
                isAuthenticated: false,
                profileInfo:null
            }
        }
        case AUTHENTICATING_USER_SUCCESS: {
            return {
                ...state,
                isAuthenticated: true,
                authenticationInProgress: false,
                profileInfo: action.res,
                
            }
        }
        case UPDATE_USER: {
            return {
                ...state,
                profileInfo: state.profileInfo ? {...state.profileInfo, ...action.payload} : state.profileInfo
            }
        }
        case AUTHENTICATING_USER_ERROR: {
            return {
                ...state,
                authenticationInProgress: false,
                isAuthenticated: false,
                profileInfo: null,
            }
        }
        case LOGOUT_USER: {
            return {
                ...state,
                authenticationInProgress: false,
                isAuthenticated: false,
                profileInfo: null
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}
export default reducer;