import { FTECHING, FTECHING_SUCCESS, FTECHING_ERROR } from './action';

export const initialState = {
    fetchingData: false,
    blogInfo: null,
    errMsg: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FTECHING: {
            return {
                ...state,
                fetchingData: true,
            }
        }
        case FTECHING_SUCCESS: {
            return {
                ...state,
                fetchingData: false,
                blogInfo: action.res,
                errMsg: null
            }
        }
        case FTECHING_ERROR: {
            return {
                ...state,
                fetchingData: false,
                blogInfo: null,
                errMsg: action.errMsg
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}
export default reducer;